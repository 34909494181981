import { AxiosResponseHeaders } from "axios";
import { ContentDispositionFilenameRegexp } from "../util/regexp";

export async function emptyCall() {
  return undefined;
}

export function extractFilenameFromContentDisposition(
  headers: AxiosResponseHeaders
) {
  const filename =
    headers["content-disposition"]
      ?.match(ContentDispositionFilenameRegexp)
      ?.find((_, i) => i === 0)
      ?.replace(/['"]/g, "")
      ?.split("=") ?? [];

  if (filename.length === 2) {
    return decodeURIComponent(filename[1]);
  }

  return;
}
