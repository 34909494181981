import { Box, Button, Flex, Tag, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  useParams,
  useOutletContext,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Empty from "../../../components/atom/Empty";
import { addApprove, getNaviKey } from "../../../libs/apis/content";
import MonitoringModal from "./MonitoringModal";
import useDateParse from "../../../libs/util/dateParse";
import {
  DetailLogResponseItemtype,
  DetailResponseDataType,
} from "../../../libs/models/monitoringModel";

type OutletProp = {
  data: DetailResponseDataType;
  isPossible: boolean;
};

export default function InspectionLog() {
  const { data, isPossible } = useOutletContext<OutletProp>();
  const [rejectModal, setRejectModal] = useState(false);
  const [addApproveModal, setAddApproveModal] = useState(false);
  const [overlapState, setOverlapState] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const status = searchParams.get("status") ?? "NONE";
  const param = useParams();

  useEffect(() => {
    if (!isPossible) {
      setOverlapState(true);
    }
  }, [isPossible]);

  function ButtonGroup() {
    const { id } = useParams();

    async function redirectPage() {
      try {
        const nextKey = await getNaviKey(param.id as string, status);
        if (!nextKey.next) {
          navigate(`/monitoring?status=${status}`);
        } else {
          navigate(`/monitoring/${nextKey.next}?status=${status}`);
        }
      } catch (error) {
        alert("다음 콘텐츠를 불러올 수 없습니다.");
        navigate(`/monitoring?status=${status}`);
      }
    }

    async function onClickAddApprove() {
      try {
        await addApprove(data.id);
      } catch (error) {
        alert("승인 추가 실패");
      }
      redirectPage();
    }
    if (!isPossible) {
      return (
        <>
          <MonitoringModal
            isOpen={overlapState}
            onClose={() => setOverlapState(false)}
            modalTitle="검수가 불가능합니다."
            modalBody="해당 콘텐츠는 다른 어드민 유저가 검수 중입니다."
            actionText="확인"
            cancleText=""
          />
          <Text
            marginBottom="30px"
            textAlign="center"
            fontSize="md"
            fontWeight="600"
            color="gray.400"
          >
            다른 어드민 유저가 검수 중입니다.
          </Text>
        </>
      );
    }
    if (data.status === "DENY" || data.status === "APPROVE") {
      return <Empty />;
    }

    return (
      <>
        <MonitoringModal
          isOpen={rejectModal}
          onClose={() => setRejectModal(false)}
          modalTitle="반려"
          modalBody="반려 사유를 작성합니다. 반려 상태로 선택 후에는 되돌릴 수 없으니 신중히 선택해주세요."
          cancleText="취소"
          actionText="반려"
          redirectUrl={`/monitoring/${id}/edit?status=${data.status}`}
          actionBtnColorScheme="red"
        />
        <MonitoringModal
          isOpen={addApproveModal}
          onClose={() => setAddApproveModal(false)}
          modalTitle="승인"
          modalBody="해당 콘텐츠를 승인합니다. 승인 즉시 마켓에 노출되니 신중히 선택해주세요."
          cancleText="취소"
          actionText="승인"
          actionBtnColorScheme="teal"
          clickEvent={() => onClickAddApprove()}
        />
        <Flex justifyContent="flex-end" marginBottom="30px">
          <Button
            onClick={() => setRejectModal(true)}
            size="md"
            variant="solid"
            marginLeft="5px"
            padding="10px 45px"
            backgroundColor="red.400"
            fontSize="md"
            fontWeight="600"
            color="white"
            _hover={{ backgroundColor: "red.400" }}
            _active={{ backgroundColor: "red.400" }}
          >
            반려
          </Button>
          <Button
            onClick={() => setAddApproveModal(true)}
            size="md"
            variant="solid"
            marginLeft="5px"
            padding="10px 45px"
            backgroundColor="blue.400"
            fontSize="md"
            fontWeight="600"
            color="white"
            _hover={{ backgroundColor: "blue.400" }}
            _active={{ backgroundColor: "blue.400" }}
          >
            승인
          </Button>
        </Flex>
      </>
    );
  }

  function Log() {
    if (data.reviewLogs.length === 0) {
      return (
        <Box
          marginBottom="20px"
          padding="40px 45px"
          borderRadius="16px"
          backgroundColor="white"
          boxShadow="base"
        >
          <Text
            textAlign="center"
            fontSize="md"
            fontWeight="600"
            color="gray.400"
          >
            반려 기록이 없습니다.
          </Text>
        </Box>
      );
    }

    return (
      <>
        {data.reviewLogs.map((el) => (
          <Box
            key={el.id}
            position="relative"
            marginBottom="20px"
            padding="20px 25px"
            borderRadius="16px"
            backgroundColor="white"
            boxShadow="base"
          >
            <Text position="absolute" top="20px" right="25px">
              {`검수자: ${el.reviewer.nickname}`}
            </Text>

            <Text
              marginBottom="20px"
              fontSize="md"
              fontWeight="700"
              color="gray.700"
            >
              {useDateParse(el.logAt, "yyyyMMddHHmmss")}
            </Text>
            <StatusTag el={el} />
          </Box>
        ))}
      </>
    );
  }

  return (
    <>
      <ButtonGroup />
      <Text marginBottom="30px" fontSize="md" fontWeight="700" color="gray.700">
        검수된 기록
      </Text>
      <Log />
    </>
  );
}

type StatusTagProps = {
  el: DetailLogResponseItemtype;
};

function StatusTag({ el }: StatusTagProps) {
  switch (el.status) {
    case "EDITORIAL":
    case "APPROVE":
      return (
        <Tag
          marginRight="10px"
          padding="0px 8px"
          backgroundColor="blue.400"
          borderRadius="2px"
          fontSize="xs"
          fontWeight="700"
          color="white"
        >
          승인된 콘텐츠
        </Tag>
      );
    case "DENY":
      return (
        <>
          <Box marginBottom="20px">
            {el.denyTags.map((tag) => {
              return (
                <Tooltip
                  label={tag.description}
                  openDelay={700}
                  hasArrow={true}
                  color="white"
                  key={tag.id}
                >
                  <Tag
                    marginRight="10px"
                    marginBottom="10px"
                    padding="0px 8px"
                    backgroundColor="red.400"
                    borderRadius="2px"
                    fontSize="xs"
                    fontWeight="700"
                    color="white"
                    userSelect="none"
                  >
                    {tag.content}
                  </Tag>
                </Tooltip>
              );
            })}
          </Box>
          <Text
            marginBottom="10px"
            fontSize="xs"
            fontWeight="500"
            color="gray.500"
          >
            기타
          </Text>
          <Text
            marginBottom="10px"
            fontSize="md"
            fontWeight="400"
            color="gray.500"
          >
            {el.denyReason}
          </Text>
        </>
      );
    default:
      return <Empty />;
  }
}
