import React, { ReactNode, useCallback } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Empty from "../atom/Empty";

type ContentPageHeaderPropsType = {
  title: string;
  menu?: ReactNode;
};

export default function ContentPageHeader({
  title,
  menu,
}: ContentPageHeaderPropsType) {
  const RenderMenu = useCallback(() => {
    if (menu) {
      return <Box display="flex">{menu}</Box>;
    }
    return <Empty />;
  }, [menu]);

  return (
    <Flex justify="space-between" margin="30px 0">
      <Text fontSize="2xl" fontWeight="bold">
        {title}
      </Text>
      <RenderMenu />
    </Flex>
  );
}
