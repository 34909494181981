import React, { ComponentProps, createContext, ReactNode } from "react";
import { getAccessToken, isLoggedIn } from "../libs/apis/apiClient";
import { Navigate } from "react-router-dom";
import { hasRole } from "../libs/util/jwt";
import { Role } from "../libs/datas/roles";

export type AuthProviderProps = {
  loginPath: string;

  unauthorizedPath: string;
  roles: Role[];
  children: ReactNode;
};
export default function AuthProvider({
  loginPath,
  unauthorizedPath,
  roles,
  children,
}: AuthProviderProps) {
  if (!isLoggedIn()) {
    return <Navigate to={loginPath} />;
  }

  if (!hasRole(getAccessToken() ?? "", ...roles)) {
    return <Navigate to={unauthorizedPath} />;
  }

  return <>{children}</>;
}
