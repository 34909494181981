import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const editorList = [
  {
    userId: "7027c16e-91f8-4cac-8a69-306be8d48e8e",
    nickname: "에디터1",
    email: "editor01@label.pixt.is",
  },
  {
    userId: "8fcd86bd-ac06-48a4-b2b2-6d17cba4fdd7",
    nickname: "에디터2",
    email: "editor02@label.pixt.is",
  },
  {
    userId: "0afb7cd9-e95c-4494-beba-b29003294743",
    nickname: "에디터3",
    email: "editor03@label.pixt.is",
  },
  {
    userId: "da74d0d2-70ae-413d-ab9a-ff3e37b725b1",
    nickname: "에디터4",
    email: "editor04@label.pixt.is",
  },
  {
    userId: "3a6e1ed4-aadf-4a7f-9e75-e1e6f7c852e2",
    nickname: "에디터5",
    email: "editor05@label.pixt.is",
  },
  {
    userId: "e92f041f-8c8d-43c4-a90b-2d9b21fa7eab",
    nickname: "에디터6",
    email: "editor06@label.pixt.is",
  },
  {
    userId: "970af1a5-7d96-486d-ac12-d039036939ec",
    nickname: "에디터7",
    email: "editor07@label.pixt.is",
  },
  {
    userId: "e0402866-1a02-417d-8885-e74dfc95b73f",
    nickname: "에디터8",
    email: "editor08@label.pixt.is",
  },
  {
    userId: "c6f963d3-4182-4b85-a6ed-b30d8ae22534",
    nickname: "에디터9",
    email: "editor09@label.pixt.is",
  },
  {
    userId: "c0ad9c34-f501-4346-9193-721815784d7b",
    nickname: "에디터10",
    email: "editor10@label.pixt.is",
  },
  {
    userId: "7604940e-4a85-4cb1-bb40-6916381b7198",
    nickname: "에디터11",
    email: "editor11@label.pixt.is",
  },
  {
    userId: "d4d5d4a3-e4da-4d2c-b11c-992c52d3a038",
    nickname: "에디터12",
    email: "editor12@label.pixt.is",
  },
  {
    userId: "493b9bb3-e44e-4763-8e81-151dd882a278",
    nickname: "에디터13",
    email: "editor13@label.pixt.is",
  },
  {
    userId: "648eff05-cc8e-447a-8350-37c44c9b470f",
    nickname: "에디터14",
    email: "editor14@label.pixt.is",
  },
  {
    userId: "91f6935a-aec0-4b29-b57d-ec22302560cc",
    nickname: "에디터15",
    email: "editor15@label.pixt.is",
  },
  {
    userId: "9dd8aecb-dc98-475c-ad12-4d80210f9864",
    nickname: "에디터16",
    email: "editor16@label.pixt.is",
  },
  {
    userId: "77223dc6-0be5-4734-9cc9-d4b23687779b",
    nickname: "에디터17",
    email: "editor17@label.pixt.is",
  },
  {
    userId: "b9dfaf54-5dae-4957-b8c5-4b181dfc777a",
    nickname: "에디터18",
    email: "editor18@label.pixt.is",
  },
  {
    userId: "65f36fea-7760-4b33-a743-ca278cbe4abe",
    nickname: "에디터19",
    email: "editor19@label.pixt.is",
  },
  {
    userId: "08f030fa-e506-4f2f-9767-289bc1f944d3",
    nickname: "에디터20",
    email: "editor20@label.pixt.is",
  },
];

export type EditorSelectorProps = {
  isOpen: boolean;
  onClose: () => void;
  selectLabeller: (userId: string) => void;
};

export default function EditorSelector({
  isOpen,
  onClose,
  selectLabeller,
}: EditorSelectorProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>작업자 목록</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {editorList.map((value) => (
            <Button
              key={value.userId}
              marginY="4px"
              w="100%"
              colorScheme="gray"
              onClick={() => selectLabeller(value.userId)}
            >
              {value.nickname}({value.email})
            </Button>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
