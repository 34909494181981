import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { addSupplier } from "../../libs/apis/supplier";

function isValidInputValue(
  email: string | undefined | null,
  phone: string | undefined | null,
  nickname: string | undefined | null
): boolean {
  return Boolean(email) || Boolean(phone) || Boolean(nickname);
}

export default function AddSupplier() {
  const navigate = useNavigate();
  const [isShowAlert, setIsShowAlert] = useState(false);

  const onClickAddSupplier = async (formData: FormData) => {
    const email = formData.get("email") as string;
    const phone = formData.get("phone") as string;
    const nickname = formData.get("nickName") as string;

    const valid = isValidInputValue(email, phone, nickname);

    if (valid === false) {
      alert("모든값을 입력하세요.");
      return;
    }

    try {
      await addSupplier({
        email,
        phone,
        nickname,
      });
      setIsShowAlert(true);
      setTimeout(() => {
        navigate("/supplier", { replace: true });
      }, 3000);
    } catch (err: any) {
      if (typeof err?.message !== "string") return;
      if (
        err.message === undefined ||
        (!err.message.includes("Value.Email") &&
          !err.message.includes("Value.Nickname") &&
          !err.message.includes("Value.Phone"))
      ) {
        alert("알 수 없는 에러로 실패함");
      }
      if ((err.message as string).includes("Value.Email")) {
        alert("이메일을 확인하세요.");
      }
      if ((err.message as string).includes("Value.Nickname")) {
        alert("닉네임을 확인하세요.");
      }
      if ((err.message as string).includes("Value.Phone")) {
        alert("전화번호를 확인하세요.");
      }
    }
  };

  return (
    <Box
      position="relative"
      padding="100px"
      borderRadius="24px"
      boxShadow="xl"
      bg="white"
      color="gray.700"
    >
      {isShowAlert ? (
        <Alert
          position="absolute"
          top="-44px"
          left="50%"
          width="400px"
          transform={"translateX(-50%)"}
          status="success"
          zIndex="1"
          boxShadow="xl"
          colorScheme="green.500"
          color="white"
          borderRadius="6px"
        >
          <AlertIcon color="white" />
          <AlertTitle>새로운 공급자를 추가하였습니다.</AlertTitle>
        </Alert>
      ) : (
        <></>
      )}
      <Box width="350px" margin="0 auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickAddSupplier(new FormData(e.currentTarget));
          }}
        >
          <Text marginBottom="20px" fontSize="24px" fontWeight="700">
            공급자 추가
          </Text>
          <Text>이메일</Text>
          <Input
            name="email"
            type="email"
            marginBottom="10px"
            size="md"
            color="black"
          />
          <Text>닉네임</Text>
          <Input
            name="nickName"
            type="text"
            marginBottom="10px"
            size="md"
            color="black"
          />
          <Text>휴대폰번호</Text>
          <Input
            name="phone"
            type="number"
            marginBottom="50px"
            size="md"
            color="black"
          />
          <Button
            type="submit"
            variant="solid"
            colorScheme="teal"
            fontSize="18px"
            width="100%"
          >
            추가
          </Button>
        </form>
      </Box>
    </Box>
  );
}
