import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import { deleteSupplier } from "../../../libs/apis/supplier";
import { SupplierDataResponse } from "../../../libs/models/supplierModel";

interface UserDetailInfoRightPropsType {
  userDetail: SupplierDataResponse;
}

export default function UserDetailInfoRight({
  userDetail,
}: UserDetailInfoRightPropsType) {
  const { hasPassword } = userDetail;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isShowAlert, setIsShowAlert] = useState(false);

  async function deleteItem(id: string | undefined) {
    if (id === undefined) return;
    try {
      await deleteSupplier(id);
      onClose();
      setIsShowAlert(true);
      setTimeout(() => {
        navigate("/supplier");
      }, 3000);
    } catch (e) {
      alert("삭제를 실패했습니다.");
    }
  }

  return (
    <Box width="48%">
      {isShowAlert ? (
        <Alert
          position="absolute"
          top="-44px"
          left="50%"
          width="400px"
          transform={"translateX(-50%)"}
          status="success"
          zIndex="1"
          boxShadow="xl"
          colorScheme="red.500"
          color="white"
          borderRadius="6px"
        >
          <AlertIcon color="white" />
          <AlertTitle>삭제를 완료하였습니다.</AlertTitle>
        </Alert>
      ) : (
        <></>
      )}
      <Box position="relative">
        <Box position="absolute" top="0" right="0">
          <Link to="edit">
            <Button colorScheme="teal">수정</Button>
          </Link>
          <Button
            colorScheme="white"
            variant="ghost"
            marginLeft="10px"
            onClick={onOpen}
          >
            삭제
          </Button>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>삭제</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                해당 공급자를 삭제하시겠습니까? <br />
                한번 삭제한 정보는 되돌릴 수 없습니다.
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="gray" mr={3} onClick={onClose}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={() => deleteItem(id)}>
                  삭제
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
      <Box marginTop="115px">
        <Text fontWeight="700" paddingBottom="8px">
          비밀번호 설정
        </Text>
        {hasPassword ? <PwSet /> : <NotSetPw />}
      </Box>
    </Box>
  );
}

function PwSet() {
  return (
    <Text color="blue.400" display="flex" alignItems="center">
      <CheckCircleIcon marginRight="5px" /> 비밀번호가 설정되어 있습니다.
    </Text>
  );
}

function NotSetPw() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Text marginRight="10px" color="gray.400">
        아직 비밀번호 설정이 되지 않았습니다.
      </Text>
      <Button size="xs" colorScheme="teal" onClick={onOpen}>
        안내메일 전송
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="18px" fontWeight="700">
            안내메일 전송
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            해당 공급자의 휴대폰번호로 비밀번호 설정 안내메일를 전송합니다.
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="white"
              mr={3}
              onClick={onClose}
            >
              취소
            </Button>
            <Button colorScheme="teal">전송</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
