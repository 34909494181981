import { isLoggedIn } from "../apis/apiClient";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useCheckLogin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/", { replace: true });
    }
  }, [pathname]);
}
