import { useLocation, useSearchParams } from "react-router-dom";

export default function makeTitle() {
  const { pathname } = useLocation();
  const [serchParams] = useSearchParams();
  const status = serchParams.get("status");

  const title = document.querySelector("title");
  if (title === null) return;

  if (pathname.includes("supplier")) {
    title.innerHTML = "Supplier List";
  }

  if (pathname.includes("supplier/")) {
    title.innerHTML = "Supplier Detail";
  }

  if (status === "NONE") {
    title.innerHTML = "Monitoring Status NONE ";
  }

  if (status === "DENY") {
    title.innerHTML = "Monitoring Status DENY ";
  }

  if (status === "APPROVE") {
    title.innerHTML = "Monitoring Status APPROVE ";
  }
}
