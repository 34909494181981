import React, { FormEvent, useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useTimeout } from "../../libs/hooks/useTimeout";
import {
  fetchUserList,
  FetchUserListResponse,
  UserData,
} from "../../libs/apis/user";
import { useQuery } from "@tanstack/react-query";
import { useWindowSize } from "../../libs/hooks/useWindowSize";
import { emptyCall } from "../../libs/apis/util";
import { ErrorResponse } from "../../libs/apis/common";
import { generateLicense } from "../../libs/apis/content";
import { downloadFile } from "../../libs/util/downloadFile";

export default function LicenseGeneratorPage() {
  const [tab, setTab] = useState(0);
  const [plan, setPlan] = useState("PRO");
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [ownerName, setOwnerName] = useState("");
  const [contentId, setContentId] = useState("");

  const handleOnClickIssue = useCallback(async () => {
    if (plan.length === 0 || contentId.length === 0) {
      return;
    }

    let resultFile: File | undefined;
    switch (tab) {
      case 0:
        if (selectedUser === undefined) {
          return;
        }
        resultFile = await generateLicense(plan, contentId, {
          userId: selectedUser.username,
        });
        break;
      case 1:
        if (ownerName.length === 0) {
          return;
        }
        resultFile = await generateLicense(plan, contentId, { ownerName });
        break;
    }

    if (resultFile === undefined) {
      return;
    }
    await downloadFile(resultFile);
  }, [tab, plan, selectedUser, ownerName, contentId]);

  return (
    <HStack height="100%">
      <VStack
        margin="auto"
        padding="24px"
        borderRadius="24px"
        boxShadow="xl"
        bg="white"
        color="gray.700"
        minW="480px"
      >
        <Heading mr="auto" size="lg">
          라이센스 생성기
        </Heading>
        <Box h="8px" />
        <PlanSelector plan={plan} onChangePlan={setPlan} />
        <Box h="16px" />

        <FormControl isRequired>
          <FormLabel>라이센스 권리자</FormLabel>
          <Tabs w="100%" size="md" variant="enclosed" onChange={setTab}>
            <TabList>
              <Tab>유저 검색</Tab>
              <Tab>직접 입력</Tab>
            </TabList>
            <TabPanels>
              <TabPanel py="16px" px="0">
                <UserFinder
                  selectedUser={selectedUser}
                  onSelectUser={setSelectedUser}
                />
              </TabPanel>
              <TabPanel py="16px" px="0">
                <Input
                  name="license_owner"
                  placeholder="상호명, 이메일, 휴대폰번호"
                  value={ownerName}
                  onChange={(e) => setOwnerName(e.target.value)}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>컨텐츠 아이디</FormLabel>
          <Input
            name="content_id"
            placeholder="컨텐츠 식별자"
            value={contentId}
            onChange={(e) => setContentId(e.target.value)}
          />
        </FormControl>
        <Box h="16px" />
        <Button
          variant="solid"
          colorScheme="teal"
          fontSize="18px"
          width="100%"
          onClick={handleOnClickIssue}
        >
          발급
        </Button>
      </VStack>
    </HStack>
  );
}

type PlanSelectorProps = {
  plan: string;
  onChangePlan: (newPlan: string) => void;
};

function PlanSelector({ plan, onChangePlan }: PlanSelectorProps) {
  return (
    <FormControl isRequired>
      <FormLabel>플랜</FormLabel>
      <Select
        name="plan"
        value={plan}
        onChange={(event) => onChangePlan(event.target.value)}
      >
        <option value="JUNIOR">주니어 플랜</option>
        <option value="CREATOR">크리에이터 플랜</option>
        <option value="PRO">프로 플랜</option>
      </Select>
    </FormControl>
  );
}

type UserFinderProps = {
  selectedUser?: UserData;
  onSelectUser: (value: UserData) => void;
};

function UserFinder({ selectedUser, onSelectUser }: UserFinderProps) {
  return (
    <VStack alignItems="start">
      <UsernameSearchInput onSelectUser={onSelectUser} />
      <Text fontSize="sm">선택된 유저</Text>
      {selectedUser && (
        <>
          <Text>휴대폰번호: {selectedUser.phone ?? "-"}</Text>
          <Text>이메일: {selectedUser.email ?? "-"}</Text>
          <Text>닉네임: {selectedUser.nickname}</Text>
        </>
      )}
    </VStack>
  );
}

type UsernameSearchInputProps = {
  onSelectUser: (value: UserData) => void;
};

function UsernameSearchInput({ onSelectUser }: UsernameSearchInputProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const handleUsernameSearch = useCallback(async () => {
    const query = usernameInputRef.current?.value;
    if (query === undefined || query.length === 0) return;
    onOpen();
    setQuery(query);
  }, []);

  const handleOnSubmit = useCallback(
    async (event: FormEvent<HTMLDivElement>) => {
      event.preventDefault();
      await handleUsernameSearch();
    },
    [handleUsernameSearch]
  );

  const handleOnSelectUser = useCallback((value: UserData) => {
    onClose();
    onSelectUser(value);
  }, []);

  const { restart } = useTimeout(handleUsernameSearch, 300);

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={usernameInputRef}
      closeOnBlur={false}
    >
      <PopoverAnchor>
        <VStack as="form" w="100%" onSubmit={handleOnSubmit}>
          <Input
            ref={usernameInputRef}
            name="username"
            placeholder="닉네임, 이메일, 휴대폰번호"
            onChange={restart}
          />
        </VStack>
      </PopoverAnchor>

      <PopoverContent minW={usernameInputRef.current?.clientWidth}>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          검색 결과
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={onClose} />
        <PopoverBodyUserSearchResult
          query={query}
          onSelectUser={handleOnSelectUser}
        />
      </PopoverContent>
    </Popover>
  );
}

type UserSearchResultProps = {
  query: string;
  onSelectUser: (value: UserData) => void;
};

function PopoverBodyUserSearchResult(props: UserSearchResultProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();

  let maxH: string | undefined;
  if (targetRef.current !== null && height !== undefined) {
    const clientTop = targetRef.current.getBoundingClientRect().top;
    maxH = `${height - clientTop - 16}px`;
  }
  return (
    <PopoverBody ref={targetRef} maxH={maxH} overflowY="scroll">
      <UserSearchResult {...props} />
    </PopoverBody>
  );
}

function UserSearchResult({ query, onSelectUser }: UserSearchResultProps) {
  const fetch = useCallback(() => {
    if (query.length === 0) return emptyCall();
    return fetchUserList(query, 0, 20);
  }, [query]);

  const { data, status, error } = useQuery<
    FetchUserListResponse | undefined,
    ErrorResponse
  >([query], fetch);

  if (query.length === 0) return <CircularProgress isIndeterminate />;

  switch (status) {
    case "loading":
      return <CircularProgress isIndeterminate />;
    case "error":
      if (error !== null) {
        return <Box>실패! {error.message}</Box>;
      }
      return <Box>알 수 없는 에러</Box>;
  }

  if (data === undefined) return <Box>No result</Box>;

  return (
    <VStack divider={<Divider />}>
      {data.map((value) => (
        <Box
          key={value.username}
          w="100%"
          p="8px"
          alignItems="start"
          cursor="pointer"
          _hover={{
            bgColor: "gray.100",
          }}
          onClick={() => onSelectUser(value)}
        >
          <Text>휴대폰번호: {value.phone ?? "-"}</Text>
          <Text>이메일: {value.email ?? "-"}</Text>
          <Text>닉네임: {value.nickname}</Text>
        </Box>
      ))}
    </VStack>
  );
}
