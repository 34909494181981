import React from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../libs/apis/apiClient";

export default function EntryPage() {
  return isLoggedIn() ? (
    <Navigate to="/home" replace={true} />
  ) : (
    <Navigate to="/login" replace={true} />
  );
}
