import axios from "axios";
import { LoginRequest, LoginResponse } from "../models/loginModel";
import { apiClient, removeAccessToken, setAccessToken } from "./apiClient";

const loginClient = axios.create({
  baseURL: "https://api.stockfolio.ai",
});

export async function login(request: LoginRequest): Promise<boolean> {
  try {
    const { data } = await loginClient.post<LoginResponse>(
      "/user/sign-in",
      request
    );
    setAccessToken(data.token);
    return true;
  } catch (e) {
    return false;
  }
}

export async function logout() {
  removeAccessToken();
}

export async function checkLogin() {
  try {
    const { data } = await apiClient.get("/info");
    // console.log(data);
  } catch (error) {
    // console.log(error);
  }
}
