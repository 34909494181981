import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";

type HeaderMenuPropsType = {
  listLength: number;
  listName: string;
  resetOffsetCurrentNum: () => void;
};

export default function HeaderMenu({
  listName,
  listLength,
  resetOffsetCurrentNum,
}: HeaderMenuPropsType) {
  const [searchParams] = useSearchParams();

  const StyledButtonList = [
    {
      name: "대기",
      activeBg: "purple.400",
      key: "NONE",
    },
    {
      name: "반려",
      activeBg: "red.400",
      key: "DENY",
    },
    {
      name: "승인",
      activeBg: "blue.400",
      key: "APPROVE",
    },
  ];

  const status = searchParams.get("status") ?? "NONE";

  return (
    <>
      <Text fontSize="2xl" color="gray.700" fontWeight="700" padding="30px 0">
        모니터링
      </Text>
      <Flex justifyContent="space-between" paddingBottom="30px">
        <Flex>
          {StyledButtonList.map((el) => {
            const isActive = status === el.key;

            return (
              <Link to={`?status=${el.key}`} key={el.key}>
                <Button
                  onClick={() => resetOffsetCurrentNum()}
                  marginRight="10px"
                  borderRadius="50px"
                  backgroundColor={isActive ? el.activeBg : "transparent"}
                  fontWeight="600"
                  color={isActive ? "white" : "gray.600"}
                  _hover={{ backgroundColor: el.activeBg, color: "white" }}
                  _active={{ backgroundColor: el.activeBg, color: "white" }}
                >
                  {el.name}
                </Button>
              </Link>
            );
          })}
        </Flex>
        <Flex alignItems="center">
          <Text
            marginRight="10px"
            paddingTop="5px"
            fontSize="sm"
            fontWeight="500"
            color="gray.600"
          >
            {listName}
          </Text>
          <Text fontSize="2xl" fontWeight="700">
            {listLength}개
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
