import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Checkbox,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import MonitoringModal from "./MonitoringModal";
import { addDeny, getDenyTag, getNaviKey } from "../../../libs/apis/content";
import { DenyTagType } from "../../../libs/models/monitoringModel";
interface TagStyledItemType extends DenyTagType {
  isSelected: boolean;
}

export default function Reject() {
  const [cancle, setCancle] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [tagStyled, setTagStyled] = useState<TagStyledItemType[]>([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") ?? "NONE";
  const param = useParams();

  async function setTagInit() {
    try {
      const tagsData = await getDenyTag();
      setTagStyled(tagsData.map((tag) => ({ ...tag, isSelected: false })));
    } catch (error) {
      alert("태그 정보를 받아오지 못했습니다.");
    }
  }

  const tagColorObj = {
    activeBgColor: "red.400",
    nomalBgColor: "red.50",
    activeFontColor: "white",
    nomalFontColor: "red.300",
  };

  function changeTagStyle(el: TagStyledItemType) {
    setTagStyled((prev) =>
      prev.map((prevEl) =>
        prevEl.id === el.id ? { ...prevEl, isSelected: !el.isSelected } : prevEl
      )
    );
  }

  async function submitData(data: FormData) {
    const checkedValue = data.getAll("tag");
    const textareaValue = data.get("textarea") as string | null;

    if (checkedValue.length <= 0) {
      setIsValid(true);
      return;
    }
    setSubmit(true);
    const requestData = {
      tags: checkedValue.map((el) => Number(el)),
      denyReason: textareaValue ?? "",
    };

    if (!param.id) return;

    try {
      await addDeny(param.id, requestData);
    } catch (error) {
      alert("반려 시도가 실패되었습니다.");
      return;
    }
  }

  async function redirectPage() {
    try {
      const nextKey = await getNaviKey(param.id as string, status);
      if (!nextKey.next) navigate("/monitoring");
      else navigate(`/monitoring/${nextKey.next}?status=${status}`);
    } catch (error) {
      alert("다음 콘텐츠를 불러올 수 없습니다.");
      navigate(`/monitoring?status=${status}`);
    }
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submitData(new FormData(e.currentTarget));
  }

  useEffect(() => {
    setTagInit();
  }, []);

  return (
    <>
      <MonitoringModal
        isOpen={isValid}
        onClose={() => setIsValid(false)}
        modalTitle="제출"
        modalBody="태그를 한개 이상 선택해주세요."
        actionText="확인"
      />
      <MonitoringModal
        isOpen={submit}
        onClose={() => setSubmit(false)}
        modalTitle="제출"
        modalBody="반려 후 취소가 불가능하며 반려 사유를 제출합니다."
        cancleText="취소"
        actionText="확인"
        clickEvent={() => redirectPage()}
      />
      <Text marginBottom="30px" fontSize="md" fontWeight="700" color="gray.700">
        반려 사유를 작성합니다.
      </Text>
      <form onSubmit={(e) => onSubmit(e)}>
        <Box
          padding="20px 25px"
          borderRadius="16px"
          backgroundColor="white"
          boxShadow="base"
        >
          <Text
            marginBottom="30px"
            fontSize="md"
            fontWeight="700"
            color="gray.700"
          >
            반려 사유
          </Text>
          <Flex flexWrap="wrap" marginBottom="30px">
            {tagStyled.map((el) => (
              <Box key={el.id}>
                <Checkbox
                  id={el.id.toString()}
                  name="tag"
                  value={el.id}
                  display="none"
                />
                <Tooltip
                  label={el.description}
                  openDelay={700}
                  hasArrow={true}
                  color="white"
                >
                  <FormLabel
                    htmlFor={el.id.toString()}
                    backgroundColor={
                      el.isSelected
                        ? tagColorObj.activeBgColor
                        : tagColorObj.nomalBgColor
                    }
                    padding="4px"
                    borderRadius="2px"
                    fontSize="xs"
                    fontWeight="700"
                    color={
                      el.isSelected
                        ? tagColorObj.activeFontColor
                        : tagColorObj.nomalFontColor
                    }
                    cursor="pointer"
                    onClick={() => changeTagStyle(el)}
                    userSelect="none"
                  >
                    {el.content}
                  </FormLabel>
                </Tooltip>
              </Box>
            ))}
          </Flex>
          <Text
            marginBottom="10px"
            fontSize="xs"
            fontWeight="500"
            color="gray.500"
          >
            기타
          </Text>
          <Textarea
            name="textarea"
            placeholder="추가적인 반려사유가 있다면 작성해주세요."
            defaultValue={"안녕하세요 작가님, 영상을 제출해주셔서 감사합니다."}
          />
        </Box>
        <Flex justifyContent="flex-end" marginTop="30px">
          <Button
            onClick={() => setCancle(true)}
            variant="ghost"
            _hover={{ background: "grandparent" }}
            _active={{ background: "grandparent" }}
          >
            취소
          </Button>
          <MonitoringModal
            isOpen={cancle}
            onClose={() => setCancle(false)}
            modalTitle="취소"
            modalBody="작성하시던 내용은 저장되지않습니다."
            cancleText="취소"
            actionText="확인"
            redirectUrl={`/monitoring/${param.id}`}
          />
          <Button type="submit" marginLeft="10px" colorScheme="teal">
            확인
          </Button>
        </Flex>
      </form>
    </>
  );
}
