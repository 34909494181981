import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import LoginWindow from "./components/LoginWindow";
import Logo from "../../assets/Logo.png";

export default function Login() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      backgroundColor="#edeff5"
    >
      <Box>
        <Box marginBottom="70px" textAlign="center">
          <Text fontWeight="700" fontSize="4xl">
            안녕하세요
          </Text>
          <Text fontSize="3xl">
            <Image display="inline-block" src={Logo} alt="Pixt Logo" /> 의
            어드민 페이지입니다.
          </Text>
        </Box>
        <LoginWindow />
      </Box>
    </Box>
  );
}
