import React, { useState } from "react";
import { Box, Flex, Image, Text, Tag, Button } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { Link, useSearchParams } from "react-router-dom";
import Empty from "../../components/atom/Empty";
import useDateParse from "../../libs/util/dateParse";
import HeaderMenu from "./components/HeaderMenu";
import { useQuery } from "@tanstack/react-query";
import { fetchMonitoringList } from "../../libs/apis/content";
import { StatusType } from "../../libs/models/monitoringModel";

export type reviewStatusType = {
  color: string;
  tagText: string;
};

type reviewStatusObjType = {
  [key in StatusType]: reviewStatusType;
};

export const reviewStatusObj: reviewStatusObjType = {
  NONE: { color: "purple.400", tagText: "검수중" },
  DENY: { color: "red.400", tagText: "반려" },
  APPROVE: { color: "blue.400", tagText: "승인" },
  EDITORIAL: { color: "purple.400", tagText: "에디토리얼" },
};

const itemLimit = 20;

export default function MonitoringList() {
  const [searchParams] = useSearchParams();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const offset = (currentPageNum - 1) * itemLimit;

  function useFetchListData(limit: number, offset: number, status: string) {
    const { data } = useQuery(
      [limit, offset, status],
      () => fetchMonitoringList(limit, offset, status),
      {
        keepPreviousData: true,
        staleTime: 1000,
        refetchInterval: 5000,
      }
    );
    return data;
  }

  const data = useFetchListData(
    itemLimit,
    offset,
    searchParams.get("status") ?? "NONE"
  );

  const totalPages = data === undefined ? 1 : Math.ceil(data.total / itemLimit);

  function increaseOffset() {
    setCurrentPageNum((prev) => {
      if (prev >= totalPages) return totalPages;
      return prev + 1;
    });
  }

  function decreaseOffset() {
    setCurrentPageNum((prev) => {
      if (prev <= 1) return 1;
      return prev - 1;
    });
  }

  function resetOffsetCurrentNum() {
    setCurrentPageNum(1);
  }
  if (!data) return <Empty />;

  return (
    <Box>
      <HeaderMenu
        listName="대기중인 콘텐츠"
        listLength={data.total}
        resetOffsetCurrentNum={resetOffsetCurrentNum}
      />
      {data.data.length > 0 ? (
        <>
          <Flex
            flexWrap="wrap"
            margin="-1%"
            alignItems="flex-start"
            listStyleType="none"
            as="ul"
          >
            {data?.data.map((el) => {
              const reviewStatusTag = (
                <Tag
                  marginRight="5px"
                  padding="2px 6px"
                  float="left"
                  backgroundColor={
                    el?.status ? reviewStatusObj[el.status].color : ""
                  }
                  borderRadius="2px"
                  color="white"
                  fontSize="xs"
                  fontWeight="700"
                >
                  {el?.status ? reviewStatusObj[el.status].tagText : ""}
                </Tag>
              );

              const isTag =
                el?.status === "DENY" ||
                el?.status === "APPROVE" ||
                el?.status === "EDITORIAL" ||
                el?.isMonitoring;

              return (
                <Box
                  key={el.id}
                  margin="1%"
                  width="23%"
                  backgroundColor="white"
                  padding="16px"
                  borderRadius="16px"
                  cursor="pointer"
                  as="li"
                >
                  <Link to={`/monitoring/${el.id}?status=${el.status}`}>
                    <Box position="relative">
                      <Image
                        src={el.thumb}
                        alt={el.subject}
                        marginBottom="16px"
                        width="100%"
                        height="185px"
                        objectFit="cover"
                        borderRadius="16px"
                      />
                      {el.isMonitoring ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          position="absolute"
                          top="0"
                          left="0"
                          bottom="0"
                          right="0"
                          backgroundColor="blackAlpha.600"
                          borderRadius="16px"
                        >
                          <Text fontWeight="700" fontSize="lg" color="white">
                            검수 중입니다.
                          </Text>
                        </Box>
                      ) : (
                        <Empty />
                      )}
                    </Box>
                    {isTag ? reviewStatusTag : <Empty />}
                    <Text fontSize="md" fontWeight="700" color="gray.700">
                      {el.subject}
                    </Text>
                    <Text
                      paddingBottom="5px"
                      fontSize="sm"
                      fontWeight="400"
                      color="gray.500"
                    >
                      업로드일자: {useDateParse(el.createdAt, "yyyyMMddHHmmss")}
                    </Text>
                    {el.denyAt && (
                      <Text
                        paddingBottom="10px"
                        fontSize="sm"
                        fontWeight="400"
                        color="#F56565"
                      >
                        마지막 반려 일자:
                        {useDateParse(el.denyAt, "yyyyMMddHHmmss")}
                      </Text>
                    )}
                    {el.tags?.map((tagName) => (
                      <Tag
                        key={tagName}
                        padding="4px 6.5px"
                        marginRight="5px"
                        marginBottom="5px"
                        size="sm"
                        variant="solid"
                        colorScheme="teal"
                      >
                        {tagName}
                      </Tag>
                    ))}
                  </Link>
                </Box>
              );
            })}
          </Flex>
          <Box
            marginTop="30px"
            padding="15px"
            borderRadius="8px"
            boxShadow="base"
            bg="white"
            fontSize="14px"
          >
            <Flex justify="end" alignItems="center">
              <Text marginRight="10px">
                {currentPageNum} / {totalPages}
              </Text>
              <Button
                onClick={decreaseOffset}
                _hover={{ bg: "gray.50" }}
                _active={{ bg: "gray.50" }}
                padding="0"
                height="25px"
                leftIcon={<ChevronLeftIcon />}
                variant="ghost"
              />
              <Button
                onClick={increaseOffset}
                _hover={{ bg: "gray.50" }}
                _active={{ bg: "gray.50" }}
                padding="0"
                height="25px"
                rightIcon={<ChevronRightIcon />}
                variant="ghost"
              />
            </Flex>
          </Box>
        </>
      ) : (
        <Text
          padding="100px 0"
          textAlign="center"
          fontSize="lg"
          fontWeight="bold"
          color="gray.400"
        >
          데이터가 없습니다.
        </Text>
      )}
    </Box>
  );
}
