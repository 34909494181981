import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import VideoPlayer from "../../components/atom/VideoPlayer";
import Empty from "../../components/atom/Empty";
import useDateParse from "../../libs/util/dateParse";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  fetchChangeLogs,
  fetchLabellerAssign,
  fetchStockContent,
  getContentForAdmin,
  labelDone,
  labellerAssign,
  updateCategoryTags,
  updateContentForAdmin,
  updateInternalTags,
  updateLabelTags,
  updateTags,
} from "../../libs/apis/content";
import { decode, encode } from "universal-base64url";
import { setPage, setSearchKeyword } from "./util";
import { hasRole } from "../../libs/util/jwt";
import { getAccessToken } from "../../libs/apis/apiClient";
import { values } from "mobx";
import EditorSelector from "./component/EditorSelector";

const checkboxCategoryTags = [
  "인물",
  "인물-제스쳐",
  "사물",
  "자연",
  "풍경",
  "모션",
  "그린스크린",
  "음식",
  "식물",
  "동물",
  "기타",
];

async function getFullContent(id: string) {
  const [content, logs, labellers] = await Promise.all([
    getContentForAdmin(id),
    fetchChangeLogs(id),
    fetchLabellerAssign(),
  ]);

  return {
    content,
    logs,
    labellers,
  };
}

export default function DetailPage() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const [searchParams] = useSearchParams();
  const c = searchParams.get("c");
  const hasCursor = c !== null;
  const {
    lim,
    p: page,
    q,
  } = JSON.parse(decode(c ?? "")) as {
    lim: number;
    p: number;
    q?: string;
  };
  const p = page - 1;
  const isLabeler = hasRole(getAccessToken() ?? "", "LABELER");
  const isLabelManager = hasRole(getAccessToken() ?? "", "LABEL_MANAGER");
  const isFullAccess = hasRole(getAccessToken() ?? "", "SUPER_ADMIN", "ADMIN");

  const { data, refetch } = useQuery(
    [`getContentDetail/id:${id}`],
    () => getFullContent(id),
    {
      keepPreviousData: true,
    }
  );

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [labelTags, setLabelTags] = useState<string[]>([]);
  const [internalTags, setInternalTags] = useState<string[]>([]);
  const [categoryTags, setCategoryTags] = useState<string[]>([]);

  const oldContent = data?.content;
  const labellers = data?.labellers ?? [];
  const labeller = labellers.find(
    (value) => value.labellerId === oldContent?.labellerId
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onInit = useCallback(() => {
    if (oldContent === undefined) return;
    setSubject(oldContent.subject);
    setDescription(oldContent.description);
    setTags(oldContent.tags);
    setLabelTags(oldContent.labelTags);
    setInternalTags(oldContent.internalTags);
    setCategoryTags(oldContent.categoryTags);
  }, [oldContent]);

  // const onClickDone = useCallback(async () => {
  //   await labelDone(id);
  // }, [id]);

  const onClickReset = useCallback(async () => {
    await refetch();
    onInit();
  }, [refetch]);

  const onClickSave = useCallback(async () => {
    if (oldContent === undefined) return;

    const tasks: Promise<unknown>[] = [];
    if (isFullAccess) {
      if (isChangedTags(oldContent.tags ?? [], tags)) {
        tasks.push(updateTags(id, tags));
      }

      if (
        oldContent.subject !== subject ||
        oldContent.description !== description
      ) {
        tasks.push(updateContentForAdmin(id, subject, description));
      }
    }

    if (isLabeler) {
      tasks.push(labelDone(id));
    }

    if (isChangedTags(oldContent.labelTags ?? [], labelTags)) {
      tasks.push(updateLabelTags(id, labelTags));
    }

    if (isChangedTags(oldContent.categoryTags ?? [], categoryTags)) {
      tasks.push(updateCategoryTags(id, categoryTags));
    }

    if (isChangedTags(oldContent.internalTags ?? [], internalTags)) {
      tasks.push(updateInternalTags(id, internalTags));
    }

    if (tasks.length > 0) {
      const results = await Promise.allSettled(tasks);
      if (results.every((value) => value.status === "fulfilled")) {
        alert("저장 성공");
      } else {
        alert("저장 실패");
      }
    } else {
      alert("변경 사항이 없습니다");
    }
  }, [
    id,
    oldContent,
    subject,
    description,
    tags,
    labelTags,
    internalTags,
    categoryTags,
  ]);

  const moveToEditor = useCallback(
    (id: string, p: number, lim: number, q?: string) => {
      navigate({
        pathname: `/content/${id}?c=${encode(
          JSON.stringify({
            p: p + 1,
            lim,
            q,
          })
        )}`,
      });
    },
    [navigate]
  );

  const movePage = useCallback(async () => {
    const { data } = await fetchStockContent(lim * p, lim, q, isLabeler);
    const i = data.findIndex((value) => value.id === id);

    async function next() {
      let moveId: string | undefined;
      let newP = p;
      if (data.length - 1 <= i) {
        newP++;
        const { data: newData } = await fetchStockContent(
          lim * newP,
          lim,
          q,
          isLabeler
        );
        if (newData.length > 0) {
          moveId = newData[0].id;
        }
      } else {
        moveId = data[i + 1].id;
      }

      if (moveId === undefined) {
        alert("마지막 페이지입니다.");
        return;
      }
      moveToEditor(moveId, newP, lim, q);
    }

    async function prev() {
      let moveId: string | undefined;
      let newP = p;
      if (i === 0) {
        newP--;
        if (newP < 0) {
          alert("첫번째 페이지입니다.");
          return;
        }
        const { data: newData } = await fetchStockContent(
          lim * newP,
          lim,
          q,
          isLabeler
        );

        if (newData.length > 0) {
          moveId = newData[newData.length - 1].id;
        }
      } else {
        moveId = data[i - 1].id;
      }

      if (moveId === undefined) {
        alert("첫번째 페이지입니다.");
        return;
      }
      moveToEditor(moveId, newP, lim, q);
    }

    return {
      next,
      prev,
    };
  }, [id, lim, p, q, isLabeler]);

  const prevPage = useCallback(async () => {
    const { prev } = await movePage();
    await prev();
  }, [movePage]);

  const nextPage = useCallback(async () => {
    const { next } = await movePage();
    await next();
  }, [movePage]);

  const listPage = useCallback(() => {
    searchParams.delete("c");
    setPage(searchParams, page);
    if (q !== undefined) {
      setSearchKeyword(searchParams, q);
    }
    navigate({
      pathname: "/content",
      search: searchParams.toString(),
    });
  }, [navigate, page, q]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  const onSelectLabeller = useCallback(
    async (userId: string) => {
      await labellerAssign([
        {
          contentId: id,
          labellerId: userId,
        },
      ]);
      await onClickReset();
      onClose();
    },
    [id, onClickReset]
  );

  if (oldContent === undefined) {
    return <Empty />;
  }

  return (
    <>
      <EditorSelector
        isOpen={isOpen}
        onClose={onClose}
        selectLabeller={onSelectLabeller}
      />
      <Flex width="48%" justifyContent="space-between" alignItems="center">
        <Text margin="30px 0" fontSize="2xl" fontWeight="700" color="gray.700">
          콘텐츠 상세정보
        </Text>
        {hasCursor && (
          <Box>
            <Button
              type="button"
              marginRight="10px"
              width="70px"
              boxShadow="base"
              backgroundColor="white"
              color="grey.700"
              fontSize="14px"
              _hover={{ background: "gray.200" }}
              _active={{ background: "#EDEFF5", boxShadow: "none" }}
              onClick={listPage}
            >
              목록
            </Button>
            <Button
              type="button"
              marginRight="10px"
              width="70px"
              boxShadow="base"
              backgroundColor="white"
              color="grey.700"
              fontSize="14px"
              _hover={{ background: "gray.200" }}
              _active={{ background: "#EDEFF5", boxShadow: "none" }}
              onClick={prevPage}
            >
              이전
            </Button>
            <Button
              type="button"
              width="70px"
              fontSize="14px"
              boxShadow="base"
              backgroundColor="white"
              color="grey.700"
              _hover={{ background: "gray.200" }}
              _active={{ background: "#EDEFF5", boxShadow: "none" }}
              onClick={nextPage}
            >
              다음
            </Button>
          </Box>
        )}
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Box
          width="48%"
          padding="30px"
          boxShadow="base"
          backgroundColor="white"
          borderRadius="24px"
        >
          <VideoPlayer url={oldContent.sample} />
          {isFullAccess && (
            <>
              <Text fontSize="sm" fontWeight="400" color="gray.500">
                업로드일자:{" "}
                {useDateParse(oldContent.createdAt, "yyyyMMddHHmmss")}
              </Text>
              <Heading size="md">작가정보</Heading>
              <Text fontSize="sm" fontWeight="400" color="gray.500">
                작가식별자: {oldContent?.author?.userId}
              </Text>
              <Text
                paddingBottom="10px"
                fontSize="sm"
                fontWeight="400"
                color="gray.500"
              >
                닉네임: {oldContent.author?.nickname}
              </Text>
              <Heading size="md">영상 제목</Heading>
              <Input
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                placeholder="제목"
                size="md"
              />
              <Heading size="md">영상 설명</Heading>
              <Textarea
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                placeholder="설명문"
                size="sm"
              />
              <Heading size="md">영상 태그(마켓 노출용)</Heading>
              {tags.length === 0 ? (
                <Text fontSize="sm" fontWeight="400" color="gray.500">
                  비어 있음
                </Text>
              ) : (
                <Tags
                  tags={tags}
                  onDelete={(_, ti) =>
                    setTags((prevState) => {
                      return [...prevState.filter((_, pi) => pi !== ti)];
                    })
                  }
                />
              )}
              <TagInput
                onAdd={(value) => {
                  if (value.length === 0) return;
                  setTags((prevState) => {
                    if (prevState.includes(value)) {
                      return prevState;
                    }
                    return [...prevState, value];
                  });
                }}
              />
            </>
          )}
          {(isLabeler || isLabelManager) && oldContent?.labelWorkDone && (
            <Tag
              marginRight="5px"
              padding="2px 6px"
              backgroundColor="blue.400"
              borderRadius="2px"
              color="white"
              fontSize="xs"
              fontWeight="700"
            >
              작업 완료
            </Tag>
          )}
          {isLabelManager && (
            <>
              <HStack>
                <Heading size="md">작업자</Heading>
                <Button size="sm" onClick={onOpen}>
                  변경
                </Button>
              </HStack>
              {labeller === undefined ? (
                <Text>없음</Text>
              ) : (
                <Text>
                  {labeller?.labellerNickname} / {labeller?.labellerEmail}
                </Text>
              )}
            </>
          )}
          <Box h="16px" />
          <Heading size="md">영상 태그(라벨링용)</Heading>
          {labelTags.length === 0 ? (
            <Text fontSize="sm" fontWeight="400" color="gray.500">
              비어 있음
            </Text>
          ) : (
            <Tags
              tags={labelTags}
              onDelete={(_, ti) =>
                setLabelTags((prevState) => {
                  return [...prevState.filter((_, pi) => pi !== ti)];
                })
              }
            />
          )}
          <TagInput
            onAdd={(value) => {
              if (value.length === 0) return;
              setLabelTags((prevState) => {
                if (prevState.includes(value)) {
                  return prevState;
                }
                return [...prevState, value];
              });
            }}
          />
          <Heading size="md">영상 태그(분류용)</Heading>
          <Flex flexWrap="wrap" as="ul" mb="16px" gap="2">
            {checkboxCategoryTags.map((value) => (
              <Checkbox
                key={value}
                isChecked={categoryTags.includes(value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCategoryTags((prevState) => {
                      if (prevState.includes(value)) {
                        return prevState;
                      }
                      return [...prevState, value];
                    });
                  } else {
                    setCategoryTags((prevState) => {
                      return [
                        ...prevState.filter((pValue) => pValue !== value),
                      ];
                    });
                  }
                }}
              >
                {value}
              </Checkbox>
            ))}
          </Flex>
          <Heading size="md">영상 태그(내부용)</Heading>
          {internalTags.length === 0 ? (
            <Text fontSize="sm" fontWeight="400" color="gray.500">
              비어 있음
            </Text>
          ) : (
            <Tags
              tags={internalTags}
              onDelete={(_, ti) =>
                setInternalTags((prevState) => {
                  return [...prevState.filter((_, pi) => pi !== ti)];
                })
              }
            />
          )}
          <TagInput
            onAdd={(value) => {
              if (value.length === 0) return;
              setInternalTags((prevState) => {
                if (prevState.includes(value)) {
                  return prevState;
                }
                return [...prevState, value];
              });
            }}
          />
          <HStack>
            <Button onClick={onClickSave}>저장</Button>
            <Button onClick={onClickReset}>초기화</Button>
            {/*{isLabeler && <Button onClick={onClickDone}>작업완료</Button>}*/}
          </HStack>
        </Box>
        <Box width="49%">
          {data?.logs?.map((v, i) => (
            <Box
              key={i}
              position="relative"
              marginBottom="20px"
              padding="20px 25px"
              borderRadius="16px"
              backgroundColor="white"
              boxShadow="base"
            >
              <Text position="absolute" top="20px" right="25px">
                수정자: {v.changerName}
              </Text>

              <Text
                marginBottom="20px"
                fontSize="md"
                fontWeight="700"
                color="gray.700"
              >
                {useDateParse(v.logAt, "yyyyMMddHHmmss")}
              </Text>
              <TagLogSet title="추가" tagSet={v.change.ADD} mode="POSITIVE" />
              <TagLogSet title="삭제" tagSet={v.change.DEL} mode="NEGATIVE" />
            </Box>
          ))}
        </Box>
      </Flex>
    </>
  );
}

type TagLogSetProps = {
  title: string;
  tagSet: {
    LABEL?: string[];
    INTERNAL?: string[];
    CATEGORY?: string[];
  };
  mode: "POSITIVE" | "NEGATIVE";
};

function TagLogSet({ title, tagSet, mode }: TagLogSetProps) {
  if (
    greater(tagSet.LABEL?.length, 0) ||
    greater(tagSet.CATEGORY?.length, 0) ||
    greater(tagSet.INTERNAL?.length, 0)
  ) {
    return (
      <>
        <Heading size="md">{title}</Heading>
        <TagLogs title="영상 태그(라벨링용)" tags={tagSet.LABEL} mode={mode} />
        <TagLogs title="영상 태그(분류용)" tags={tagSet.CATEGORY} mode={mode} />
        <TagLogs title="영상 태그(내부용)" tags={tagSet.INTERNAL} mode={mode} />
      </>
    );
  }

  return <Empty />;
}

type TagLogsProps = {
  title: string;
  tags?: string[];
  mode: "POSITIVE" | "NEGATIVE";
};
function TagLogs({ title, tags, mode }: TagLogsProps) {
  if (!greater(tags?.length, 0)) return <Empty />;

  return (
    <>
      <Text>{title}</Text>
      <Flex flexWrap="wrap">
        {tags?.map((tag) => (
          <Tag
            key={tag}
            marginRight="10px"
            marginBottom="10px"
            padding="0px 8px"
            backgroundColor={mode === "POSITIVE" ? "blue.400" : "red.400"}
            borderRadius="2px"
            fontSize="xs"
            fontWeight="700"
            color="white"
          >
            {tag}
          </Tag>
        ))}
      </Flex>
    </>
  );
}

type TagInputProps = {
  onAdd?: (value: string) => void;
};

function TagInput({ onAdd = () => ({}) }: TagInputProps) {
  const [tagInput, setTagInput] = useState("");
  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        onAdd(tagInput);
        setTagInput("");
      }}
    >
      <InputGroup size="md" mb="16px">
        <Input
          value={tagInput}
          onChange={(event) => setTagInput(event.target.value)}
          placeholder="새태그"
          size="md"
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" type="submit">
            추가
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
}

type TagsProps = {
  tags: string[];
  onDelete: (value: string, index: number) => void;
};

function Tags({ tags, onDelete }: TagsProps) {
  return (
    <Flex flexWrap="wrap" as="ul">
      {tags.map((el, ti) => (
        <Tag
          key={el}
          padding="4px 6.5px"
          marginRight="5px"
          marginBottom="5px"
          size="sm"
          variant="solid"
          colorScheme="teal"
          as="li"
        >
          <TagLabel>{el}</TagLabel>
          <TagCloseButton onClick={() => onDelete(el, ti)} />
        </Tag>
      ))}
    </Flex>
  );
}

function isChangedTags(oldTags: string[], newTags: string[]) {
  const oldMap = new Set<string>();
  oldTags.forEach((v) => oldMap.add(v));
  const hasNew = newTags.some((tag) => {
    if (oldMap.has(tag)) {
      oldMap.delete(tag);
      return false;
    }

    return true;
  });

  if (hasNew) {
    return true;
  }

  return oldMap.size > 0;
}

function greater(a: number | undefined, b: number) {
  if (a === undefined) {
    return false;
  }
  return a > b;
}
