import React, { useCallback } from "react";
import { Text, Flex, Box } from "@chakra-ui/react";

type PageMenuPropsType = {
  menuName: string;
  menu?: React.ReactElement;
};

export default function PageMenu({ menuName, menu }: PageMenuPropsType) {
  const SubMenu = useCallback(() => {
    if (menu) {
      return <Box display="flex">{menu}</Box>;
    }
    return <></>;
  }, [menu]);

  return (
    <Flex justify="space-between" margin="30px 0">
      <Text fontSize="2xl" fontWeight="bold">
        {menuName}
      </Text>
      <SubMenu />
    </Flex>
  );
}
