import {
  AddSupplierRequest,
  SupplierDataResponse,
  SupplierListResponse,
  UpdateSupplierRequest,
} from "../models/supplierModel";
import { makeParams } from "../util/makeQuery";
import { apiClient } from "./apiClient";

export async function fetchSupplierList(cursor?: string) {
  const { data } = await apiClient.get<SupplierListResponse>("/supplier", {
    params: makeParams({
      cursor,
    }),
  });
  return data;
}

export async function getSupplierDetail(id: string) {
  const { data } = await apiClient.get<SupplierDataResponse>(`/supplier/${id}`);
  return data;
}

export async function addSupplier(request: AddSupplierRequest) {
  try {
    await apiClient.post("/supplier", request);
  } catch (e: any) {
    throw Error(e.response.data.message);
  }
}

export async function updateSupplier({
  id,
  ...request
}: UpdateSupplierRequest) {
  try {
    await apiClient.patch(`/supplier/${id}`, request);
  } catch (e: any) {
    throw Error(e.response.data.message);
  }
}

export async function deleteSupplier(id: string) {
  try {
    await apiClient.delete(`/supplier/${id}`);
  } catch (e: any) {
    throw Error(e.response.data.message);
  }
}

export async function searchSupplier(
  searchOptions: string,
  word: string,
  cursor?: string
) {
  const { data } = await apiClient.get<SupplierListResponse>(
    `/supplier/search/${searchOptions}`,
    {
      params: makeParams({
        query: word,
        cursor,
      }),
    }
  );
  return data;
}
