import React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Text, Button } from "@chakra-ui/react";

export default function NotFoundUser() {
  const { id } = useParams();
  return (
    <Box>
      <Text fontSize="22px" fontWeight="700" color="gray.400">
        Search Error
      </Text>
      <Text
        marginBottom="10px"
        fontSize="22px"
        fontWeight="700"
        color="gray.400"
      >
        {`There ain't user named "${id}"`}
      </Text>
      <Link to="/supplier">
        <Button _hover={{ textDecoration: "none" }} colorScheme="teal">
          Back to List
        </Button>
      </Link>
    </Box>
  );
}
