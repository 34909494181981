import React from "react";
import { Box } from "@chakra-ui/react";
import ReactPlayer from "react-player";

type VideoPlayerPropsType = {
  url: string;
};

export default function VideoPlayer({ url }: VideoPlayerPropsType) {
  return (
    <>
      <Box
        marginBottom="15px"
        borderRadius="10px"
        overflow="hidden"
        _hover={{ cursor: "pointer" }}
      >
        <ReactPlayer
          controls={true}
          loop={true}
          url={url}
          width="100%"
          height="100%"
        />
      </Box>
    </>
  );
}
