import React from "react";
import {
  Flex,
  Tag,
  Button,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { checkLogin, logout } from "../../libs/apis/auth";
import { useNavigate } from "react-router-dom";

type SelectOptionType = {
  id: number;
  value: string;
  onClick: () => void;
};

export default function Header() {
  const navigate = useNavigate();
  async function onClickLogout() {
    await logout();
    navigate("/", { replace: true });
  }

  async function onClickMyPage() {
    const data = checkLogin();
  }

  onClickMyPage();

  const OPTION_VALUE: SelectOptionType[] = [
    { id: 0, value: "로그아웃", onClick: onClickLogout },
  ];

  return (
    <Flex
      position="absolute"
      justify="flex-end"
      gap={2}
      alignItems="center"
      right={30}
      top={5}
    >
      <Box>
        <Tag
          as="p"
          size="md"
          padding="6px"
          backgroundColor="gray.400"
          fontSize="14px"
          color="white"
          flexShrink={0}
          width="fit-content"
        >
          슈퍼어드민
        </Tag>
      </Box>
      <Box fontSize="14px">
        <Menu>
          <MenuButton
            size="sm"
            backgroundColor="white"
            textAlign="left"
            color="black"
            fontWeight="normal"
            as={Button}
            rightIcon={<ChevronDownIcon />}
            _hover={{ backgroundColor: "white" }}
            _active={{ backgroundColor: "white" }}
          >
            official@stockfolio.ai
          </MenuButton>
          <MenuList zIndex={3} padding="0">
            {OPTION_VALUE.map((el) => (
              <MenuItem borderRadius={5} key={el.id} onClick={onClickLogout}>
                {el.value}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}
