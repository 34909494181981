import ContentPageHeader from "../../components/block/ContentPageHeader";
import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Table, { TableHeaderType } from "../../components/block/Table";
import { useQuery } from "@tanstack/react-query";
import { fetchLabellerAssign } from "../../libs/apis/content";

const headers: TableHeaderType[] = [
  { key: "labellerNickname", ratio: 1, content: "닉네임" },
  { key: "labellerEmail", ratio: 1, content: "아이디" },
  { key: "assignTotal", ratio: 1, content: "부여 작업" },
  { key: "workDoneTotal", ratio: 1, content: "작업 완료" },
  { key: "percentage", ratio: 1, content: "진행률" },
];

export default function LabelWorkDashboard() {
  const { data } = useQuery([], () => fetchLabellerAssign(), {
    keepPreviousData: true,
    staleTime: 1000,
  });
  const list = data ?? [];
  return (
    <Box>
      <ContentPageHeader title="작업 현황" />

      <Table
        headers={headers}
        items={list.map((value) => ({
          key: value.labellerId,
          ...value,
        }))}
        onItemColumnRender={(value, columnKey) => {
          const obj = value as never;
          if (columnKey === "percentage") {
            return (
              <Text
                padding="20px 0"
                flexShrink="0"
                flexGrow="0"
                fontSize="14px"
                key={columnKey}
              >
                {((value.workDoneTotal / value.assignTotal) * 100).toFixed(2)} %
              </Text>
            );
          }
          return (
            <Text
              padding="20px 0"
              flexShrink="0"
              flexGrow="0"
              fontSize="14px"
              key={obj[columnKey]}
            >
              {obj[columnKey]}
            </Text>
          );
        }}
      />
    </Box>
  );
}
