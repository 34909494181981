import ContentPageHeader from "../../components/block/ContentPageHeader";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  getPage,
  getSearchKeyword,
  removePage,
  setPage,
  setSearchKeyword,
} from "./util";
import {
  fetchLabellerAssign,
  fetchStockContent,
  HashTag,
  labellerAssign,
  LabellerAssign,
  StockContent,
} from "../../libs/apis/content";

import { encode } from "universal-base64url";
import { hasRole } from "../../libs/util/jwt";
import { getAccessToken } from "../../libs/apis/apiClient";
import { useQuery } from "@tanstack/react-query";
import EditorSelector from "./component/EditorSelector";
function HeaderMenu() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const toast = useToast({
    title: "검색어를 입력해주세요",
    position: "top",
    status: "error",
    variant: "left-accent",
    isClosable: true,
  });

  const [idInput, setIdInput] = useState("");
  const [keyword, setKeyword] = useState("");

  const moveToEditor = useCallback(
    (id: string) => {
      navigate({
        pathname: `/content/${id}`,
      });
    },
    [navigate]
  );

  const onSearch = useCallback(
    (query: string) => {
      removePage(searchParams);
      setSearchKeyword(searchParams, query);
      navigate({
        search: searchParams.toString(),
      });
    },
    [navigate, searchParams]
  );

  const onResetSearch = useCallback(() => {
    setKeyword("");
    navigate({
      search: "",
    });
  }, [navigate]);

  return (
    <VStack>
      <HStack>
        <Box paddingStart="32px" maxW="480px" w="100%">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (keyword.length > 0) {
                onSearch(keyword);
              } else {
                toast();
              }
            }}
          >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                placeholder="제목 검색 기능"
                backgroundColor="white"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" type="submit">
                  검색
                </Button>
              </InputRightElement>
            </InputGroup>
          </form>
        </Box>
        <Button onClick={onResetSearch}>초기화</Button>
        <Spacer />
        <Popover onClose={() => setIdInput("")}>
          <PopoverTrigger>
            <Button>ID로 수정</Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="480px">
              <PopoverArrow />
              <PopoverHeader>ID로 검색</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    moveToEditor(idInput);
                  }}
                >
                  <InputGroup size="md">
                    <Input
                      value={idInput}
                      onChange={(event) => setIdInput(event.target.value)}
                      placeholder="00000000-0000-0000-0000-000000000000"
                      size="md"
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" type="submit">
                        찾기
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </form>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </HStack>
    </VStack>
  );
}

export default function ContentListPage() {
  const pageLimit = 10;
  const itemLimit = 10;
  const [searchParams] = useSearchParams();
  const page = getPage(searchParams) - 1;
  const keyword = getSearchKeyword(searchParams);

  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(0);
  const [items, setItems] = useState<StockContentUIModel[]>([]);
  const [checkList, setCheckList] = useState<Record<string, boolean>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, refetch } = useQuery(
    [`labellerAssign`],
    () => fetchLabellerAssign(),
    {
      keepPreviousData: true,
    }
  );

  const loadItemsByPage = useCallback(
    async (p: number, lim: number, keyword?: string) => {
      const { data, total } = await fetchStockContent(
        lim * p,
        lim,
        keyword,
        hasRole(getAccessToken() ?? "", "LABELER")
      );
      setTotalPage((prevState) => {
        const newTotalPage = Math.ceil(total / lim);
        if (prevState === newTotalPage) {
          return prevState;
        }

        return newTotalPage;
      });
      setItems(data.map((item) => makeUIModel(item)));
    },
    []
  );

  const movePage = useCallback(
    (page: number) => {
      setPage(searchParams, Math.min(Math.max(page, 1), totalPage));
      navigate({
        search: searchParams.toString(),
      });
    },
    [navigate, searchParams, totalPage]
  );

  const onInit = useCallback(async () => {
    await loadItemsByPage(page, itemLimit, keyword);
    setCheckList({});
  }, [page, loadItemsByPage, keyword]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  const moveToEditor = useCallback(
    (id: string) => {
      navigate({
        pathname: `/content/${id}?c=${encode(
          JSON.stringify({
            p: page + 1,
            lim: itemLimit,
            q: keyword,
          })
        )}`,
      });
    },
    [navigate, page, itemLimit, keyword]
  );

  const onSelectLabeller = useCallback(
    async (userId: string) => {
      await labellerAssign(
        items
          .filter((value) => checkList[value.id])
          .map((value) => ({
            contentId: value.id,
            labellerId: userId,
          }))
      );
      await refetch();
      await onInit();
      onClose();
    },
    [items, checkList, onInit, refetch]
  );

  const checkedLength = items.filter((value) => checkList[value.id]).length;

  return (
    <>
      <EditorSelector
        isOpen={isOpen}
        onClose={onClose}
        selectLabeller={onSelectLabeller}
      />
      <Box>
        <ContentPageHeader title="컨텐츠 목록" menu={<HeaderMenu />} />
        <HStack w="100%">
          <Checkbox
            isChecked={checkedLength === items.length}
            isIndeterminate={checkedLength > 0 && checkedLength < items.length}
            onChange={(event) => {
              setCheckList((prevState) => {
                items.forEach(
                  (value) => (prevState[value.id] = event.target.checked)
                );
                return {
                  ...prevState,
                };
              });
            }}
          />
          <Button onClick={onOpen}>선택 {checkedLength}개 작업자 할당</Button>
          <Box ml="auto" />
        </HStack>
        <Box h={4} />
        <VStack
          w="100%"
          h="100%"
          spacing={0}
          padding="15px"
          borderRadius="8px"
          boxShadow="base"
          bg="white"
          fontSize="14px"
        >
          <VStack w="100%" h="100%" overflowY="scroll">
            {items.map((value) => (
              <Box w="100%" key={value.id}>
                <StockContentItem
                  uiModel={value}
                  onEdit={() => moveToEditor(value.id)}
                  showWorkDone={hasRole(
                    getAccessToken() ?? "",
                    "LABELER",
                    "LABEL_MANAGER"
                  )}
                  showTags={hasRole(
                    getAccessToken() ?? "",
                    "ADMIN",
                    "SUPER_ADMIN"
                  )}
                  showLabeller={hasRole(
                    getAccessToken() ?? "",
                    "LABEL_MANAGER",
                    "ADMIN",
                    "SUPER_ADMIN"
                  )}
                  labellerAssign={data?.find(
                    (labeller) =>
                      labeller.labellerId === value.originData?.labellerId
                  )}
                  showCheckbox={hasRole(
                    getAccessToken() ?? "",
                    "LABEL_MANAGER",
                    "ADMIN",
                    "SUPER_ADMIN"
                  )}
                  checked={checkList[value.id] ?? false}
                  onChangeChecked={(checked) =>
                    setCheckList((prevState) => {
                      prevState[value.id] = checked;
                      return {
                        ...prevState,
                      };
                    })
                  }
                />
              </Box>
            ))}
          </VStack>
          <Box w="100%">
            <Pagination
              currentPage={page + 1}
              totalPage={totalPage}
              pageLimit={pageLimit}
              onPage={(p) => movePage(p)}
            />
          </Box>
        </VStack>
      </Box>
    </>
  );
}

type PaginationProps = {
  currentPage: number;
  totalPage: number;
  pageLimit: number;
  onPage: (page: number) => void;
};

function Pagination({
  currentPage,
  totalPage,
  pageLimit,
  onPage,
}: PaginationProps) {
  const weird = currentPage > totalPage || currentPage <= 0;
  if (weird) {
    return (
      <HStack py="16px">
        <Button onClick={() => onPage(1)}>처음으로</Button>
      </HStack>
    );
  }

  const pageStartNumber =
    Math.floor((currentPage - 1) / pageLimit) * pageLimit + 1;
  let pages = [...Array(pageLimit)].map((_, index) => index + pageStartNumber);

  const hasPrevPages = pages[0] !== 1;
  const hasNextPages = totalPage > pageStartNumber + pageLimit;
  if (!hasNextPages) {
    pages = pages.slice(0, totalPage % 10);
  }

  return (
    <HStack py="16px">
      <Spacer />
      {hasPrevPages && (
        <Button size="sm" onClick={() => onPage(currentPage - pageLimit)}>
          {"<<"}
        </Button>
      )}
      {pages.map((value) => (
        <Button
          key={value}
          size="sm"
          variant={value === currentPage ? "solid" : "outline"}
          onClick={() => onPage(value)}
        >
          {value}
        </Button>
      ))}
      {hasNextPages && (
        <Button size="sm" onClick={() => onPage(currentPage + pageLimit)}>
          {">>"}
        </Button>
      )}
      <Spacer />
    </HStack>
  );
}

interface StockContentUIModel {
  id: string;
  subject: string;
  description: string;
  thumb: string;
  preview?: string;
  tags: HashTag[];
  internalLabel: string;

  originData: StockContent;
}

type StockContentProps = {
  style?: CSSProperties;
  uiModel: StockContentUIModel;
  onEdit?: () => void;
  showWorkDone?: boolean;
  showTags?: boolean;
  showLabeller?: boolean;
  labellerAssign?: LabellerAssign;
  showCheckbox?: boolean;
  checked?: boolean;
  onChangeChecked?: (value: boolean) => void;
};

function StockContentItem({
  style,
  uiModel,
  onEdit,
  showWorkDone,
  showTags,
  showLabeller,
  labellerAssign,
  showCheckbox,
  checked,
  onChangeChecked,
}: StockContentProps) {
  const { id, subject, description, thumb, preview, tags, internalLabel } =
    uiModel;

  const [url, setURL] = useState(thumb);

  const onHover = useCallback(() => {
    setURL(preview ?? thumb);
  }, [preview, thumb]);

  const onUnHover = useCallback(() => {
    setURL(thumb);
  }, [thumb]);

  return (
    <HStack
      _hover={{ backgroundColor: "gray.100" }}
      style={style}
      px={8}
      py={4}
      align="stretch"
      onMouseEnter={onHover}
      onMouseLeave={onUnHover}
    >
      {showCheckbox && (
        <>
          <Checkbox
            isChecked={checked}
            onChange={(event) =>
              onChangeChecked && onChangeChecked(event.target.checked)
            }
          />
          <Box w={4} />
        </>
      )}

      <Image
        marginY="auto"
        align="center"
        boxSize="140px"
        borderRadius="8px"
        objectFit="cover"
        src={url}
        alt={subject}
      />
      <VStack p={4} w="100%" align="start" spacing={0}>
        {showWorkDone && uiModel.originData.labelWorkDone && (
          <Tag
            marginRight="5px"
            padding="2px 6px"
            backgroundColor="blue.400"
            borderRadius="2px"
            color="white"
            fontSize="xs"
            fontWeight="700"
          >
            작업 완료
          </Tag>
        )}

        {showLabeller &&
          (labellerAssign === undefined ? (
            <Tag
              marginRight="5px"
              padding="2px 6px"
              backgroundColor="red.400"
              borderRadius="2px"
              color="white"
              fontSize="xs"
              fontWeight="700"
            >
              작업자 미할당
            </Tag>
          ) : (
            <Text>
              작업자 : {labellerAssign?.labellerNickname}(
              {labellerAssign?.labellerEmail})
            </Text>
          ))}

        <Text color="gray.500" fontSize="sm">
          {id}
          {" ("}
          {internalLabel ?? "-"}
          {")"}
        </Text>
        <Heading fontSize="xl">{subject}</Heading>
        <Text color="gray.500" fontSize="md">
          {description}
        </Text>
        {showTags && (
          <>
            <Center h="16px" w="100%">
              <Divider size="2px" orientation="horizontal" />
            </Center>
            <HStack wrap="wrap" shouldWrapChildren>
              {tags.map((value) => (
                <Tag
                  size="md"
                  key={value.id}
                  borderRadius="full"
                  variant="outline"
                  color="#5381F6"
                  mb="8px"
                >
                  {value.content}
                </Tag>
              ))}
            </HStack>
          </>
        )}
      </VStack>
      <Center>
        <IconButton
          variant="outline"
          aria-label="Edit Data"
          icon={<EditIcon />}
          size="lg"
          onClick={onEdit}
        />
      </Center>
    </HStack>
  );
}

function makeUIModel(content: StockContent) {
  return {
    id: content.id,
    subject: content.subject,
    description: content.description,
    thumb: content.thumb,
    preview: content.preview,
    tags: content.tags,
    internalLabel: content.internalLabel,

    originData: content,
  } as StockContentUIModel;
}
