import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSupplierDetail, updateSupplier } from "../../../libs/apis/supplier";
import NotFoundUser from "./NotFonundUser";

export default function UserDetailEdit() {
  const { id } = useParams();

  if (id === undefined) return <NotFoundUser />;
  return <Edit id={id} />;
}

type EditProps = {
  id: string;
};

function Edit({ id }: EditProps) {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuery(["supplierDetail", id], () =>
    getSupplierDetail(id)
  );

  function isValidInputValue(
    email: string | undefined | null,
    nickname: string | undefined | null
  ): boolean {
    return Boolean(email) || Boolean(nickname);
  }

  const onClickUpdateSupplier = async (formData: FormData) => {
    const email = formData.get("email") as string;
    const nickname = formData.get("nickName") as string;
    const phone = formData.get("phone") as string;

    const valid = isValidInputValue(email, nickname);

    if (valid === false) {
      alert("이메일과 닉네임 필드를 입력하세요.");
    }

    try {
      await updateSupplier({
        email,
        nickname,
        phone: phone ?? "",
        id,
      });
      setIsShowAlert(true);
      setTimeout(() => {
        navigate(`/supplier/${id}`, { replace: true });
      }, 3000);
    } catch (err: any) {
      if (
        typeof err.message === "undefined" ||
        (!(err.message as string).includes("Value.Email") &&
          !(err.message as string).includes("Value.Nickname") &&
          !(err.message as string).includes("Value.Phone"))
      ) {
        alert("알 수 없는 에러로 실패함");
      }
      if ((err.message as string).includes("Value.Email")) {
        alert("이메일을 확인하세요.");
      }
      if ((err.message as string).includes("Value.Nickname")) {
        alert("닉네임을 확인하세요.");
      }
      if ((err.message as string).includes("Value.Phone")) {
        alert("전호번호를 확인하세요.");
      }
    }
  };

  if (!data) return <NotFoundUser />;

  return (
    <Box
      position="relative"
      padding="100px"
      borderRadius="24px"
      boxShadow="base"
      bg="white"
      color="gray.700"
    >
      {isShowAlert ? (
        <Alert
          position="absolute"
          top="-44px"
          left="50%"
          width="400px"
          transform={"translateX(-50%)"}
          status="success"
          zIndex="1"
          boxShadow="xl"
          colorScheme="green.500"
          color="white"
          borderRadius="6px"
        >
          <AlertIcon color="white" />
          <AlertTitle>공급자 정보를 수정하였습니다.</AlertTitle>
        </Alert>
      ) : (
        <></>
      )}
      <Box width="350px" margin="0 auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickUpdateSupplier(new FormData(e.currentTarget));
          }}
        >
          <Text marginBottom="20px" fontSize="24px" fontWeight="700">
            공급자 정보 수정
          </Text>
          <Text>이메일</Text>
          <Input
            name="email"
            type="email"
            defaultValue={data.email}
            marginBottom="10px"
            size="md"
            color="black"
          />
          <Text>닉네임</Text>
          <Input
            name="nickName"
            type="text"
            defaultValue={data.nickname}
            marginBottom="10px"
            size="md"
            color="black"
          />
          <Text>휴대폰번호</Text>
          <Input
            name="phone"
            type="tel"
            defaultValue={data.phone?.replace("+82", "0")}
            marginBottom="50px"
            size="md"
            color="black"
          />
          <Flex justifyContent="space-between">
            <Button
              onClick={() => navigate(`/supplier/${id}`, { replace: true })}
              type="button"
              width="32%"
              variant="outline"
              border="1px solid #E2E8F0"
              fontSize="18px"
              _hover={{ bg: "white" }}
              _active={{ bg: "white" }}
              colorScheme="gray"
            >
              취소
            </Button>
            <Button
              type="submit"
              variant="solid"
              colorScheme="teal"
              fontSize="18px"
              width="65%"
            >
              수정
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
}
