import { userApiClient } from "./apiClient";
import { AxiosError } from "axios";
import { ErrorResponse } from "./common";

export type UserData = {
  username: string;
  register: string;
  email: string;
  phone: string;
  nickname: string;
  createdAt: string;
  hasPassword: boolean;
};

export type FetchUserListResponse = UserData[];

export async function fetchUserList(q: string, offset: number, limit: number) {
  try {
    const { data } = await userApiClient.get<FetchUserListResponse>(
      "/v1/user",
      {
        params: {
          q,
          offset,
          limit,
        },
      }
    );
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response === undefined) {
        console.log(error.message);
        return;
      }
      const { data } = error.response;
      throw data as ErrorResponse;
    } else {
      console.log(error);
    }
  }
}
