import { Role } from "./roles";

export type menuType = { title: string; to: string; roles?: Role[] };

export type navType = {
  title: string;
  menus: menuType[];
  roles?: Role[];
};

export const navData: navType[] = [
  {
    title: "공급자 관리",
    menus: [
      { title: "공급자 목록", to: "/supplier" },
      { title: "공급자 추가", to: "/supplier/adduser" },
    ],
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    title: "공급자 수익/정산",
    menus: [
      { title: "출금 요청 목록", to: "/under-construction" },
      { title: "출금 완료 목록", to: "/under-construction" },
      { title: "수익 추가", to: "/under-construction" },
      { title: "수익 추가 기록", to: "/under-construction" },
      { title: "기타", to: "/under-construction" },
    ],
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    title: "마켓 관리",
    menus: [
      { title: "메인 섹션 관리", to: "/under-construction" },
      { title: "FAQ 관리", to: "/under-construction" },
      { title: "기타", to: "/under-construction" },
    ],
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    title: "마켓 콘텐츠 관리",
    menus: [
      {
        title: "모니터링",
        to: "/monitoring?status=NONE",
        roles: ["SUPER_ADMIN", "ADMIN"],
      },
      {
        title: "Label 작업 현황",
        to: "/label-work-dashboard",
        roles: ["SUPER_ADMIN", "ADMIN", "LABEL_MANAGER"],
      },
      { title: "컨텐츠 정보 수정", to: "/content" },
      {
        title: "출금 완료 목록",
        to: "/under-construction",
        roles: ["SUPER_ADMIN", "ADMIN"],
      },
      {
        title: "콘텐츠 관리",
        to: "/under-construction",
        roles: ["SUPER_ADMIN", "ADMIN"],
      },
    ],
  },
  {
    title: "관리자",
    menus: [
      { title: "관리자 목록", to: "/under-construction" },
      { title: "관리자 추가", to: "/under-construction" },
    ],
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    title: "유틸리티",
    menus: [
      {
        title: "라이센스 수동 생성기",
        to: "/util/license-generator",
        roles: ["SUPER_ADMIN", "ADMIN"],
      },
    ],
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
];
