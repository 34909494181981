import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Button, Flex, Tag, Text, Link } from "@chakra-ui/react";
import {
  Outlet,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  checkHandle,
  getContentAgreementDocDowmloadLink,
  getContentAgreementDocList,
  getHandle,
  getMonitoringDetail,
  getNaviKey,
  revokeHandle,
} from "../../libs/apis/content";
import Empty from "../../components/atom/Empty";
import { reviewStatusObj } from "./index";
import VideoPlayer from "../../components/atom/VideoPlayer";
import useDateParse from "../../libs/util/dateParse";
import {
  AgreementDocDownloadLinkType,
  AgreementDocResponseDataType,
} from "../../libs/models/monitoringModel";

export default function Detail() {
  const [isPossible, setIsPossible] = useState(false);
  type AgreeDocListType = {
    id: string;
    filename: string;
    downloadLink: string;
  };
  const [agreeDocList, setAgreeDocList] = useState<AgreeDocListType[] | null>(
    null
  );
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const initRef = useRef(true);
  const { id } = useParams() as { id: string };
  const [serchParams] = useSearchParams();
  const status = serchParams.get("status") ?? "";
  const navigate = useNavigate();

  function makeAgreeDocList(
    docList: AgreementDocResponseDataType[],
    docLink: AgreementDocDownloadLinkType[]
  ): AgreeDocListType[] {
    const newArr: AgreeDocListType[] = [];
    for (let i = 0; i < docList.length; i++) {
      const newObj = {
        id: docList[i].id,
        filename: docList[i].filename,
        downloadLink: docLink[i].url as string,
      };
      newArr.push(newObj);
    }
    return newArr;
  }

  async function agreeMentDocRequsetAPIBundle(res: string) {
    try {
      const docList = await getContentAgreementDocList(res);
      const docDownLoadLinkList = await Promise.all(
        docList.map((el) => getContentAgreementDocDowmloadLink(el.id))
      );
      setAgreeDocList(makeAgreeDocList(docList, docDownLoadLinkList));
    } catch (error) {
      console.log(error);
    }
  }

  const detail = useQuery(
    [`getMonitoringDetail/id:${id}`],
    () => getMonitoringDetail(id),
    {
      keepPreviousData: true,
      staleTime: 1000,
      // refetchInterval: 5000,
      onSuccess: (data) => agreeMentDocRequsetAPIBundle(data.id),
    }
  );
  const data = detail.data;

  async function nextRedirectPage() {
    try {
      const nextKey = await getNaviKey(id, status);
      if (!nextKey.next) alert("다음 콘텐츠가 없습니다.");
      else navigate(`/monitoring/${nextKey.next}?status=${status}`);
    } catch (error) {
      alert("다음 콘텐츠를 불러올 수 없습니다.");
      navigate(`/monitoring?status=${status}`);
    }
  }
  async function prevRedirectPage() {
    try {
      const prevKey = await getNaviKey(id, status);
      if (!prevKey.prev) alert("이전 콘텐츠가 없습니다.");
      else navigate(`/monitoring/${prevKey.prev}?status=${status}`);
    } catch (error) {
      alert("다음 콘텐츠를 불러올 수 없습니다.");
      navigate(`/monitoring?status=${status}`);
    }
  }

  function onBeforeUnLoad(e: BeforeUnloadEvent) {
    e.preventDefault();
    revokeHandle(id);
    if (intervalRef.current) clearInterval(intervalRef.current);
    e.returnValue = "";
  }

  async function getOrRevokeHandle() {
    if (!initRef.current) return;
    initRef.current = false;
    if (intervalRef.current) return;
    try {
      await getHandle(id);
      intervalRef.current = setInterval(() => getHandle(id), 5000);
    } catch (error) {
      return;
    }

    window.addEventListener("beforeunload", onBeforeUnLoad);
  }

  const [isTag] = useState(
    !!data &&
      (data.status === "DENY" ||
        data.status === "APPROVE" ||
        isPossible === true)
  );

  async function checkAndHandleFlow() {
    try {
      await checkHandle(id as string);
      setIsPossible(true);
    } catch (error) {
      return;
    }
  }

  useEffect(() => {
    checkAndHandleFlow();
    getOrRevokeHandle();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        revokeHandle(id);
        window.removeEventListener("beforeunload", onBeforeUnLoad);
      }
    };
  }, []);

  if (!data) return <Empty />;

  return (
    <>
      <Flex width="48%" justifyContent="space-between" alignItems="center">
        <Text margin="30px 0" fontSize="2xl" fontWeight="700" color="gray.700">
          콘텐츠 상세정보
        </Text>
        <Box>
          <Button
            type="button"
            marginRight="10px"
            width="70px"
            boxShadow="base"
            backgroundColor="white"
            color="grey.700"
            fontSize="14px"
            _hover={{ background: "gray.200" }}
            _active={{ background: "#EDEFF5", boxShadow: "none" }}
            onClick={() => prevRedirectPage()}
          >
            이전
          </Button>
          <Button
            type="button"
            width="70px"
            fontSize="14px"
            boxShadow="base"
            backgroundColor="white"
            color="grey.700"
            _hover={{ background: "gray.200" }}
            _active={{ background: "#EDEFF5", boxShadow: "none" }}
            onClick={() => nextRedirectPage()}
          >
            다음
          </Button>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Box
          width="48%"
          padding="30px"
          boxShadow="base"
          backgroundColor="white"
          borderRadius="24px"
        >
          <VideoPlayer url={data.sample} />
          {isTag ? (
            <Tag
              marginBottom="15px"
              padding="2px 6px"
              borderRadius="2px"
              backgroundColor={reviewStatusObj[data.status].color}
              color="white"
              fontSize="xs"
              fontWeight="700"
            >
              {reviewStatusObj[data.status].tagText}
            </Tag>
          ) : (
            <Empty />
          )}
          <Text
            display="flex"
            alignItems="center"
            fontSize="md"
            fontWeight="700"
            color="gray.700"
          >
            영상 제목: {data?.subject}
            {data.isEditorial ? (
              <Tag
                marginLeft="7px"
                size="sm"
                variant="outline"
                colorScheme="teal"
                color="teal"
                fontWeight="800"
              >
                EDITORIAL
              </Tag>
            ) : (
              <Empty />
            )}
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.500">
            업로드일자: {useDateParse(data?.createdAt, "yyyyMMddHHmmss")}
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.500">
            ID: {data.author.id}
          </Text>
          <Text
            paddingBottom="10px"
            fontSize="sm"
            fontWeight="400"
            color="gray.500"
          >
            닉네임: {data.author.nickname}
          </Text>
          <Text
            marginBottom="15px"
            fontSize="md"
            fontWeight="400"
            color="gray.700"
          >
            영상 설명: {data?.description}
          </Text>
          <Flex flexWrap="wrap" as="ul" marginBottom="15px">
            영상 태그:&nbsp;
            {data?.tags.map((el) => (
              <Tag
                key={el}
                padding="4px 6.5px"
                marginRight="5px"
                marginBottom="5px"
                size="sm"
                variant="solid"
                colorScheme="teal"
                as="li"
              >
                {el}
              </Tag>
            ))}
          </Flex>
          {(agreeDocList?.length ?? 0) > 0 ? (
            <Box fontSize="sm" display="flex">
              <Text color="gray.500" paddingRight="6px">
                콘텐츠 동의서:
              </Text>
              <Box>
                {agreeDocList &&
                  agreeDocList?.map((el) => (
                    <Link
                      key={el.id}
                      href={el.downloadLink}
                      target="_blank"
                      color="blue.500"
                      display="block"
                      paddingBottom="6px"
                      textDecor="underline"
                    >
                      {el.filename}
                    </Link>
                  ))}
              </Box>
            </Box>
          ) : (
            <Empty />
          )}
        </Box>
        <Box width="49%">
          <Outlet context={{ data, isPossible }} />
        </Box>
      </Flex>
    </>
  );
}
