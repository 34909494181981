import React from "react";
import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import useDateParse from "../../../libs/util/dateParse";
import { SupplierDataResponse } from "../../../libs/models/supplierModel";

type UserDetailInfoLeftPropsType = {
  userDetail: SupplierDataResponse;
};

export default function UserDetailInfoLeft({
  userDetail,
}: UserDetailInfoLeftPropsType) {
  const { email, username } = userDetail;

  type userInfoTitleType = { title: string; key: keyof SupplierDataResponse };

  const userInfoTitle: userInfoTitleType[] = [
    { title: "닉네임", key: "nickname" },
    { title: "휴대폰번호", key: "phone" },
    { title: "생성자", key: "register" },
    { title: "생성일", key: "createdAt" },
  ];

  return (
    <Box width="48%">
      <Box>
        <Text marginTop="-15px" fontSize="42px" fontWeight="700">
          {email === undefined ? <Text>이메일 없음</Text> : email}
        </Text>
        <Text fontSize="14px" color="gray.700">
          {username}
        </Text>
      </Box>
      <Box
        marginTop="40px"
        padding="40px"
        borderColor="gray.200 !important"
        border="1px solid"
        borderRadius="24px"
      >
        <Box>
          {userInfoTitle.map((el) => (
            <UnorderedList
              display="flex"
              key={el.key}
              margin="0"
              listStyleType="none"
            >
              <ListItem
                width="100px"
                flexShrink={0}
                fontWeight="700"
                paddingBottom="8px"
              >
                {el.title}
              </ListItem>
              {el.key === "createdAt" ? (
                <Text>{useDateParse(userDetail[el.key], "yyyyMMdd")}</Text>
              ) : el.key === "phone" ? (
                <Text>{userDetail[el.key]?.replace("+82", "0")}</Text>
              ) : (
                <Text>{userDetail[el.key]}</Text>
              )}
            </UnorderedList>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
