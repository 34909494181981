export default function makeQuery(
  obj: Record<string, string | number | boolean | undefined | null>
) {
  const queries: string[] = [];
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    if (value === undefined || value === null) return;
    queries.push(`${key}=${value}`);
  });

  if (queries.length === 0) return "";

  return "?" + queries.join("&");
}

export function makeParams(
  obj: Record<string, string | number | boolean | undefined | null>
) {
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    if (value === undefined || value === null) {
      delete obj[key];
    }
  });

  return { ...obj };
}
