import { Role } from "../datas/roles";

export function hasRole(jwt: string, ...role: Role[]) {
  const parts = jwt.split(".");
  if (parts.length !== 3) {
    return false;
  }

  const payload = JSON.parse(atob(parts[1]));
  const roles = payload["role"] as Role[];
  return roles?.some((value) => role.includes(value)) ?? false;
}
