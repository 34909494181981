function leftPad(value: number) {
  if (value >= 10) {
    return value;
  }

  return `0${value}`;
}

type dateType = "yyyyMMdd" | "yyyyMMddHHmmss";

export default function useDateParse(d: string | number, dateType: dateType) {
  const dateObject = new Date(d);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();
  const hour = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  if (dateType === "yyyyMMdd") {
    return `${year}-${leftPad(month)}-${leftPad(date)}`;
  }
  if (dateType === "yyyyMMddHHmmss") {
    return `${year}.${leftPad(month)}.${leftPad(date)} ${leftPad(
      hour
    )}:${leftPad(minutes)}:${leftPad(seconds)}`;
  }
}
