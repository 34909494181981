import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

type ModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: string;
  modalBody: string;
  actionText: string;
  cancleText?: string;
  actionBtnColorScheme?: string;
  redirectUrl?: string;
  clickEvent?: () => void;
};

export default function MonitoringModal({
  isOpen,
  onClose,
  modalTitle,
  modalBody,
  cancleText,
  actionText,
  redirectUrl,
  actionBtnColorScheme,
  clickEvent,
}: ModalPropsType) {
  const navigate = useNavigate();

  function onClickEvent() {
    if (clickEvent) clickEvent();
    if (redirectUrl) {
      navigate(`${redirectUrl}`, { replace: true });
    } else {
      onClose();
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{modalBody}</ModalBody>

        <ModalFooter>
          {cancleText && (
            <Button
              variant="outline"
              colorScheme="gray"
              mr={3}
              onClick={onClose}
            >
              {cancleText}
            </Button>
          )}
          <Button
            colorScheme={actionBtnColorScheme ?? "teal"}
            onClick={() => onClickEvent()}
          >
            {actionText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
