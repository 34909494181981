import axios, { AxiosRequestConfig } from "axios";

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "access_token";

export function setAccessToken(token: string) {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token);
  setAuthInterceptor(token);
}

export function getAccessToken() {
  return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
}

export function removeAccessToken() {
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  removeAuthInterceptor();
}

let authInterceptorId = 0;
export function isLoggedIn(): boolean {
  return getAccessToken() !== null;
}

function setAuthInterceptor(inputToken?: string) {
  if (authInterceptorId !== 0) removeAuthInterceptor();

  const token = inputToken ?? getAccessToken();
  if (token === null) return;
  authInterceptorId = axios.interceptors.request.use(function (config) {
    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
}

function removeAuthInterceptor() {
  if (authInterceptorId === 0) return;
  authInterceptorId = 0;
  axios.interceptors.request.eject(authInterceptorId);
}

setAuthInterceptor();

function createInstance(config: AxiosRequestConfig) {
  const instance = axios.create(config);
  instance.interceptors.request = axios.interceptors.request;
  instance.interceptors.response = axios.interceptors.response;
  return instance;
}

const apiClient = createInstance({
  baseURL: "https://i0k673qvrj.execute-api.ap-northeast-2.amazonaws.com",
});

const contentApiClient = createInstance({
  baseURL: "https://api.content.pixt.is",
});

const userApiClient = createInstance({
  baseURL: "https://api.user.pixt.is",
});

export { apiClient, contentApiClient, userApiClient };
