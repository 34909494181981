import React from "react";
import PageMenu from "./components/PageMenu";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import NotFoundUser from "./components/NotFonundUser";
import UserDetailInfoLeft from "./components/UserDetailInfoLeft";
import UserDetailInfoRight from "./components/UserDetailInfoRight";
import { useQuery } from "@tanstack/react-query";
import { getSupplierDetail } from "../../libs/apis/supplier";

export default function DetailPage() {
  const { id } = useParams();
  if (id === undefined) return <NotFoundUser />;
  return <Detail id={id} />;
}

type DetailProps = {
  id: string;
};

function Detail({ id }: DetailProps) {
  const { data } = useQuery(["supplierDetail", id], () =>
    getSupplierDetail(id)
  );

  if (!data) return <NotFoundUser />;

  return (
    <Box>
      <PageMenu menuName="공급자 상세정보" />
      <Box
        position="relative"
        display="flex"
        justifyContent="space-between"
        padding="30px"
        borderRadius="24px"
        backgroundColor="white"
        boxShadow="base"
      >
        <UserDetailInfoLeft userDetail={data} />
        <UserDetailInfoRight userDetail={data} />
      </Box>
    </Box>
  );
}
