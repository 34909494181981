import React, { FormEvent, useRef, useState } from "react";
import { Box, Input, Text, Button } from "@chakra-ui/react";
import { login, logout } from "../../../libs/apis/auth";
import { useNavigate } from "react-router-dom";
import { hasRole } from "../../../libs/util/jwt";
import { getAccessToken } from "../../../libs/apis/apiClient";
import { AllowRoles } from "../../../libs/datas/roles";

function isValidIdAndPassword(
  id: string | undefined | null,
  pw: string | undefined | null
): boolean {
  return Boolean(id) && Boolean(pw);
}

export default function LoginWindow() {
  const navigate = useNavigate();
  const enteredIdValue = useRef<HTMLInputElement | null>(null);
  const enteredPwValue = useRef<HTMLInputElement | null>(null);
  const [valid, setValid] = useState<boolean>(true);

  const emptyCheck = () => {
    const newValid = isValidIdAndPassword(
      enteredIdValue.current?.value,
      enteredPwValue.current?.value
    );
    if (valid !== newValid) {
      setValid(newValid);
    }
  };

  const onClickLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (!valid) return;
    const username = enteredIdValue.current?.value;
    const password = enteredPwValue.current?.value;
    if (username === undefined || password === undefined) return;
    const isSuccessLogin = await login({
      username,
      password,
    });

    if (isSuccessLogin) {
      if (hasRole(getAccessToken() ?? "", "ADMIN", "SUPER_ADMIN")) {
        navigate("/supplier", { replace: true });
        return;
      } else if (hasRole(getAccessToken() ?? "", ...AllowRoles)) {
        navigate("/home", { replace: true });
        return;
      }

      await logout();
      alert("권한 없음");
    } else {
      alert("계정 정보 틀림");
    }
  };

  const isInvalid = !valid;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="360px"
      height="396px"
      margin="0 auto"
      padding="30px 20px"
      backgroundColor="white"
      border="1px solid #e2e8f0"
      borderRadius="24px"
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      as="form"
    >
      <Box>
        <Text fontSize="xl" fontWeight={700}>
          관리자 로그인
        </Text>
        <Box marginTop="20px" marginBottom="20px">
          <Text paddingBottom="5px" fontSize="md">
            아이디
          </Text>
          <Input
            type="text"
            size="md"
            placeholder="아이디를 입력하세요."
            ref={enteredIdValue}
            isInvalid={isInvalid}
          />
        </Box>
        <Text paddingBottom="5px" fontSize="md">
          비밀번호
        </Text>
        <Input
          type="password"
          size="md"
          placeholder="비밀번호를 입력하세요."
          ref={enteredPwValue}
          onChange={emptyCheck}
          onBlur={emptyCheck}
          isInvalid={isInvalid}
        />
        {isInvalid && (
          <Text paddingTop="5px" fontSize="sm" color="red">
            아이디와 비밀번호를 모두 입력해주세요.
          </Text>
        )}
      </Box>
      <Button
        type="submit"
        width="100%"
        colorScheme="teal"
        onClick={(e) => onClickLogin(e)}
      >
        로그인
      </Button>
    </Box>
  );
}
