import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const theme = extendTheme(
  {
    styles: {
      global: {
        "html, body": {
          margin: 0,
        },
      },
    },

    colors: {
      brand: {
        50: "#ece7ff",
        100: "#c5bbfa",
        200: "#9b8ef1",
        300: "#6e61ea",
        400: "#5134e4",
        500: "#441bca",
        600: "#3d159e",
        700: "#320f72",
        800: "#220847",
        900: "#0f021d",
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);

export default theme;
