import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import React, { useCallback, useState, useRef } from "react";
import ContentPageHeader from "../../components/block/ContentPageHeader";
import { Link } from "react-router-dom";
import Table, { TableHeaderType } from "../../components/block/Table";
import { fetchSupplierList, searchSupplier } from "../../libs/apis/supplier";
import { useQuery } from "@tanstack/react-query";
import useDateParse from "../../libs/util/dateParse";
import { SupplierListResponse } from "../../libs/models/supplierModel";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";

const headers: TableHeaderType[] = [
  { key: "username", ratio: 6, content: "ID" },
  { key: "email", ratio: 4.8, content: "이메일" },
  { key: "phone", ratio: 2.5, content: "휴대폰번호" },
  { key: "nickname", ratio: 4, content: "닉네임" },
  { key: "register", ratio: 1.5, content: "생성자" },
  { key: "createdAt", ratio: 1.8, content: "생성일" },
  { key: "etc", ratio: 0.7 },
];

export default function SupplierList() {
  const stacks = useRef<(string | undefined)[]>([]);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [count, setCount] = useState<number>(1);

  // TODO: useSearchParams query string 이용하여 페이지 이동 시 새로고침 해도 데이터 유지
  // const [searchParams, setSearchParams] = useSearchParams();
  // const pageNumber = searchParams.get("page") ?? 1;

  type searchType = {
    type: string;
    word: string;
  } | null;
  const [search, setSearch] = useState<searchType>(null);

  const { data } =
    search === null
      ? useQuery(
          [`supplierList?cursor=${cursor}`, cursor],
          () => fetchSupplierList(cursor),
          {
            keepPreviousData: true,
            staleTime: 5000,
          }
        )
      : useQuery(
          [
            `searchSupplierList?type=${search.type}?word=${search.word}`,
            cursor,
          ],
          () => searchSupplier(search.type, search.word, cursor),
          {
            keepPreviousData: true,
            staleTime: 5000,
          }
        );

  const ColumnRender = useCallback((obj: any, key: string) => {
    if (key === "etc") {
      return (
        <Menu>
          <MenuButton paddingTop="17px">
            <Box
              padding="0 20px"
              _hover={{
                cursor: "pointer",
              }}
            >
              <Icon viewBox="0 0 12 2" color="red.500">
                <path
                  d="M1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0ZM11 0C10.45 0 10 0.45 10 1C10 1.55 10.45 2 11 2C11.55 2 12 1.55 12 1C12 0.45 11.55 0 11 0ZM6 0C5.45 0 5 0.45 5 1C5 1.55 5.45 2 6 2C6.55 2 7 1.55 7 1C7 0.45 6.55 0 6 0Z"
                  fill="#2D3748"
                />
              </Icon>
            </Box>
          </MenuButton>
          <MenuList padding={0}>
            <Link to={`/supplier/${obj.username}`}>
              <MenuItem>상세정보</MenuItem>
            </Link>
            <Link to={`/supplier/${obj.username}/edit`}>
              <MenuItem>수정</MenuItem>
            </Link>
          </MenuList>
        </Menu>
      );
    }
    return (
      <Link to={`/supplier/${obj.username}`}>
        {key === "createdAt" ? (
          <Text padding="20px 0" flexShrink="0" flexGrow="0" fontSize="14px">
            {useDateParse(obj[key], "yyyyMMdd")}
          </Text>
        ) : key === "phone" ? (
          <Text
            padding="20px 0"
            flexShrink="0"
            flexGrow="0"
            fontSize="14px"
            key={obj[key]}
          >
            {obj[key]?.replace("+82", "0")}
          </Text>
        ) : (
          <Text
            padding="20px 0"
            flexShrink="0"
            flexGrow="0"
            fontSize="14px"
            key={obj[key]}
          >
            {obj[key]}
          </Text>
        )}
      </Link>
    );
  }, []);

  return (
    <Box>
      <ContentPageHeader
        title="공급자 목록"
        menu={
          <HeaderMenu
            setSearch={setSearch}
            setCursor={setCursor}
            setCount={setCount}
          />
        }
      />
      {data?.result.length === 0 ? (
        <NoResultsTable searchWord={search?.word} data={data} />
      ) : (
        <Table
          headers={headers}
          items={data?.result ?? []}
          onItemColumnRender={ColumnRender}
          footer={
            <Pagination
              cursor={cursor}
              count={count ?? 1}
              setCount={setCount}
              data={data}
              nextBtn={() => {
                stacks.current.push(cursor);
                setCursor(data?.PaginationToken);
                // setSearchParams({ page: `${count + 1}` });
              }}
              prevBtn={() => {
                setCursor(stacks.current.pop());
                // setSearchParams({ page: `${count - 1}` });
              }}
            />
          }
        />
      )}
    </Box>
  );
}

type HeaderMenuType = {
  setSearch: (state: { type: string; word: string }) => void;
  setCursor: (state: string | undefined) => void;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

function HeaderMenu({ setSearch, setCursor, setCount }: HeaderMenuType) {
  const [searchType, setSearchType] = useState(false);

  const onClickSearchSupplier = (formData: FormData) => {
    const selectedWay = formData.get("searchType");
    const inputValue = formData.get("inputValue");

    if (inputValue === "" || null || undefined) return;

    setSearch({ type: selectedWay as string, word: inputValue as string });
    setCursor("");
    setCount(1);
  };

  function onChangeSearchType() {
    setSearchType(!searchType);
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onClickSearchSupplier(new FormData(e.currentTarget));
        }}
      >
        <InputGroup>
          <Select
            onChange={onChangeSearchType}
            defaultValue="email"
            name="searchType"
            width="110px"
            marginRight="10px"
            borderColor="gray.300"
            border="1px solid"
            backgroundColor="white"
          >
            <option value="email">이메일</option>
            <option value="phone">전화번호</option>
            <option value="nickname">닉네임</option>
          </Select>
          <Input
            name="inputValue"
            // pattern={searchType ? "[0-9]+" : "/^[\u3131-\u318E\uAC00-\uD7A3a-zA-Z0-9@.]+$/"}
            type={searchType ? "tel" : "search"}
            width="300px"
            placeholder="검색어를 입력해주세요."
            backgroundColor="white"
            fontSize="md"
            color="blackAlpha.900"
            borderColor="gray.300"
            border="1px solid"
            _focus={{
              border: 0,
            }}
          />
          <InputRightElement>
            <Button
              type="submit"
              variant="solid"
              borderColor="gray.300 !important"
              border="1px solid"
              borderTopLeftRadius="0px"
              borderBottomLeftRadius="0px"
              backgroundColor="gray.100"
              fontSize="12px"
              color="gray.700"
              _hover={{
                backgroundColor: "gray.100",
              }}
              _active={{ backgroundColor: "gray.100" }}
            >
              검색
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
      <Link to="/supplier/adduser">
        <Button marginLeft="10px" size="md" colorScheme="teal">
          공급자 추가
        </Button>
      </Link>
    </>
  );
}

type PaginationPropType = {
  nextBtn: () => void;
  prevBtn: () => void;
  data: SupplierListResponse | undefined;
  cursor: string | undefined;
  count: number | null;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

function Pagination({
  nextBtn,
  prevBtn,
  data,
  cursor,
  count,
  setCount,
}: PaginationPropType) {
  function increase() {
    setCount((pervState: number) => {
      return pervState + 1;
    });
  }

  function decrease() {
    setCount((pervState: number) => {
      return pervState - 1;
    });
  }

  return (
    <Box
      padding="15px"
      borderRadius="8px"
      boxShadow="base"
      bg="white"
      fontSize="14px"
    >
      <Flex justify="end" alignItems="center">
        <Button
          onClick={() => {
            prevBtn(), decrease();
          }}
          _hover={{ bg: "gray.50" }}
          _active={{ bg: "gray.50" }}
          padding="0"
          height="25px"
          leftIcon={<ChevronLeftIcon />}
          variant="ghost"
          disabled={(count ?? 1) <= 1 ? true : false}
        />
        <Text>{count}</Text>
        <Button
          onClick={() => {
            nextBtn(), increase();
          }}
          _hover={{ bg: "gray.50" }}
          _active={{ bg: "gray.50" }}
          padding="0"
          height="25px"
          rightIcon={<ChevronRightIcon />}
          variant="ghost"
          disabled={
            cursor === data?.PaginationToken || data?.PaginationToken === null
              ? true
              : false
          }
        />
      </Flex>
    </Box>
  );
}

type NoResultsTableType = {
  searchWord?: string | undefined;
  data?: SupplierListResponse | undefined;
};

export function NoResultsTable({ searchWord, data }: NoResultsTableType) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height="500px"
      fontSize="3xl"
      fontWeight="700"
      color="gray.400"
    >
      <Box textAlign="center">
        {searchWord && data?.result.length === 0 ? (
          <>
            <Text>{searchWord}</Text>
            <Text>검색 결과가 없습니다.</Text>
            <Link to="/">
              <Button colorScheme="teal">목록으로 돌아가기</Button>
            </Link>
          </>
        ) : (
          <Text>데이터가 없습니다.</Text>
        )}
      </Box>
    </Flex>
  );
}
