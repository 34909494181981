import React from "react";
import { Button, Heading, Link, VStack } from "@chakra-ui/react";

export default function Unauthorized() {
  return (
    <VStack>
      <Heading>페이지 권한 없음</Heading>
      <Link href="/">홈으로 가기</Link>
      <Link href="/login">로그인 페이지로 가기</Link>
    </VStack>
  );
}
