import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Nav from "../block/Nav";
import { Outlet } from "react-router-dom";
import Header from "../block/Header";
import useScrollToTop from "../../libs/hooks/useScrollToTop";
import useCheckLogin from "../../libs/hooks/useCheckLogin";
import makeTitle from "../../libs/util/makeTitle";

export default function ContentPageLayout() {
  useScrollToTop();
  useCheckLogin();
  makeTitle();
  return (
    <Box position="relative" bg="#EDEFF5">
      <Header />
      <Flex justify="space-between" padding="60px 30px">
        <Nav />
        <Box width="80%">
          <Outlet />
        </Box>
      </Flex>
    </Box>
  );
}
