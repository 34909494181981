const pageQueryKey = "p";

export function getPage(src: URLSearchParams): number {
  const qPage = src.get(pageQueryKey) ?? "1";
  return parseInt(qPage);
}

export function setPage(dst: URLSearchParams, p: number) {
  dst.set(pageQueryKey, p.toString());
}

export function removePage(dst: URLSearchParams) {
  dst.delete(pageQueryKey);
}

const searchQueryKey = "q";

export function getSearchKeyword(src: URLSearchParams): string | undefined {
  return src.get(searchQueryKey) ?? undefined;
}

export function setSearchKeyword(dst: URLSearchParams, keyword: string) {
  dst.set(searchQueryKey, keyword);
}
