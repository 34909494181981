const _allowRoles = [
  "SUPER_ADMIN",
  "ADMIN",
  "LABELER",
  "LABEL_MANAGER",
] as const;

export type Role = typeof _allowRoles[number];

export const AllowRoles = _allowRoles.map((value) => value as Role);
