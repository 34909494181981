import React from "react";
import { Button, Heading, Link } from "@chakra-ui/react";

export default function NotFoundPage() {
  return (
    <>
      <Heading>not found</Heading>
      <Link href="/">홈으로</Link>
    </>
  );
}
