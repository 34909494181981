import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { NoResultsTable } from "../../pages/supplier";

export type TableItemType = {
  key: string;
};

export type TableHeaderType = {
  key: string;
  ratio: number;
  content?: ReactNode;
};

export type TableProps<T> = {
  headers: TableHeaderType[];
  items: (T & TableItemType)[];
  onItemColumnRender: (index: T, columnKey: string) => ReactNode;
  footer?: ReactNode;
};

export default function Table<T>({
  headers,
  items,
  onItemColumnRender,
  footer,
}: TableProps<T>) {
  const totalRatio = headers
    .map((value) => value.ratio)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  return (
    <>
      <UnorderedList
        margin="0 0 15px 0"
        padding="10px 20px"
        display="flex"
        justifyContent="space-between"
        backgroundColor="white"
        borderRadius="8px"
        boxShadow="base"
      >
        {headers.map((value, index) => (
          <ListItem
            flexShrink="0"
            flexGrow="0"
            fontSize="14px"
            listStyleType="none"
            key={index}
            width={`${100 * (value.ratio / totalRatio)}%`}
          >
            {value.content}
          </ListItem>
        ))}
      </UnorderedList>

      <Box>
        {items.length === 0 ? (
          <NoResultsTable />
        ) : (
          <>
            <UnorderedList
              margin="0 0 15px 0"
              backgroundColor="white"
              borderRadius="8px"
              boxShadow="lg"
            >
              {items.map((item, index) => (
                <ListItem
                  padding="0 20px"
                  listStyleType="none"
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  _hover={{ backgroundColor: "gray.50" }}
                  borderColor="gray.200 !important"
                  borderBottom="1px solid"
                >
                  {headers.map((value) => (
                    <Box
                      key={value.key}
                      width={`${100 * (value.ratio / totalRatio)}%`}
                    >
                      {onItemColumnRender(item, value.key)}
                    </Box>
                  ))}
                </ListItem>
              ))}
            </UnorderedList>
            {footer}
          </>
        )}
      </Box>
    </>
  );
}
