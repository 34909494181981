import {
  AgreementDocDownloadLinkType,
  AgreementDocResponseDataType,
  DenyTagType,
  DetailNaviDataType,
  DetailResponseDataType,
  ListResponseDataType,
  ListViewDataItemType,
} from "../models/monitoringModel";
import { contentApiClient } from "./apiClient";
import { AxiosError } from "axios";
import { ErrorResponse } from "./common";
import { extractFilenameFromContentDisposition } from "./util";
import { isBooleanObject } from "util/types";

function convertFromResListLDataToViewData(fetchData: ListResponseDataType) {
  const convertedDataList: ListViewDataItemType[] = [];

  (fetchData as ListResponseDataType).data.forEach(
    ({ ...fetchDataKeyValueRest }) => {
      const viewObject: ListViewDataItemType = {
        ...fetchDataKeyValueRest,
      };
      convertedDataList.push(viewObject);
    }
  );

  const viewData = {
    total: fetchData.total,
    data: convertedDataList,
  };

  return viewData;
}

export async function fetchMonitoringList(
  limit: number,
  offset: number,
  status: string
) {
  const { data } = await contentApiClient.get<ListResponseDataType>(
    `/review-content?status=${status}&limit=${limit}&offset=${offset}`
  );
  return convertFromResListLDataToViewData(data);
}

export async function getNaviKey(id: string, status: string) {
  const { data } = await contentApiClient.get<DetailNaviDataType>(
    `/review-content/nav?id=${id}&status=${status}`
  );
  return data;
}

export async function getMonitoringDetail(contentId: string) {
  const { data } = await contentApiClient.get<DetailResponseDataType>(
    `/review-content/${contentId}`
  );
  return data;
}

export async function getContentAgreementDocList(contentId: string) {
  const { data } = await contentApiClient.get<AgreementDocResponseDataType[]>(
    `/content/${contentId}/document`
  );
  return data;
}

export async function getContentAgreementDocDowmloadLink(docId: string) {
  const { data } = await contentApiClient.get<AgreementDocDownloadLinkType>(
    `/content/document/${docId}`
  );
  return data;
}
export async function addApprove(contentId: string) {
  try {
    await contentApiClient.post(`/review-content/${contentId}/approve`);
  } catch (error) {
    throw Error("addApprove Error");
  }
}

export async function addDeny(
  contentId: string,
  data: {
    denyReason: string;
    tags: number[];
  }
) {
  try {
    await contentApiClient.post(`/review-content/${contentId}/deny`, data);
  } catch (error) {
    throw Error("addDent Error");
  }
}

export async function checkHandle(contentId: string) {
  try {
    await contentApiClient.post(`/review-content/${contentId}/check`);
  } catch (error) {
    throw Error("checkHandle Error");
  }
}

export async function getHandle(contentId: string) {
  try {
    await contentApiClient.post(`/review-content/${contentId}/handle`);
  } catch (error) {
    throw Error("getHandle Error");
  }
}

export async function revokeHandle(contentId: string) {
  try {
    await contentApiClient.post(`/review-content/${contentId}/un-handle`);
  } catch (error) {
    throw Error("revokeHandle Error");
  }
}

export async function getDenyTag() {
  try {
    const { data } = await contentApiClient.get<DenyTagType[]>(
      "/review-content/deny-tags"
    );
    return data;
  } catch (error) {
    throw Error("getDenyTag Error");
  }
}

export type GenerateLicenseOwner = {
  userId?: string;
  ownerName?: string;
};

export async function generateLicense(
  planName: string,
  contentId: string,
  { userId, ownerName }: GenerateLicenseOwner
) {
  try {
    const { data, headers } = await contentApiClient.post<Blob>(
      "/content-license",
      { planName, contentId, userId, ownerName },
      {
        responseType: "blob",
      }
    );

    return new File(
      [data],
      extractFilenameFromContentDisposition(headers) ?? contentId,
      {
        type: headers["content-type"],
      }
    );
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response === undefined) {
        console.log(error.message);
        return;
      }
      const { data } = error.response;
      if (data instanceof Blob) {
        throw JSON.parse(await data.text()) as ErrorResponse;
      } else {
        console.log(error.message);
        return;
      }
    } else {
      console.log(error);
    }
  }
}

// TODO: refactor

function toQueryString(input: any): string {
  const query: string[] = [];
  Object.keys(input).forEach((key) => {
    const value = input[key];
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((val) => query.push(`${key}=${val}`));
      } else {
        query.push(`${key}=${value}`);
      }
    }
  });

  return query.join("&");
}

export type HashTag = {
  id: number;
  content: string;
};

export type StockContent = {
  id: string;
  authorId: string;
  subject: string;
  description: string;
  thumb: string;
  preview?: string;
  tags: HashTag[];
  internalLabel: string;
  labellerId?: string;
  labelWorkDone: boolean;
};

export type StockContentList = {
  data: StockContent[];
  total: number;
};

export async function fetchStockContent(
  offset: number,
  limit: number,
  q?: string,
  labelMode?: boolean
) {
  const queryStr = toQueryString({
    offset,
    limit,
    q: q && encodeURIComponent(q),
    labelMode: labelMode ? "" : undefined,
  });

  try {
    const { data } = await contentApiClient.get<StockContentList>(
      `/admin/content?${queryStr}`
    );
    return data;
  } catch (e) {
    return {
      data: [],
      total: 0,
    } as StockContentList;
  }
}

export type ContentForAdmin = {
  id: string;
  thumb: string;
  sample: string;
  subject: string;
  description: string;
  createdAt: number;
  author: {
    userId: string;
    nickname: string;
  };
  tags: string[];
  labelTags: string[];
  internalTags: string[];
  categoryTags: string[];
  labellerId?: string;
  labelWorkDone: boolean;
};

export async function getContentForAdmin(id: string) {
  try {
    const { data } = await contentApiClient.get<ContentForAdmin>(
      `/admin/content/${id}`
    );
    return data;
  } catch (e) {
    return undefined;
  }
}

export async function updateContentForAdmin(
  id: string,
  subject: string,
  description: string
) {
  try {
    await contentApiClient.put(`/admin/content/${id}`, {
      subject,
      description,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export async function updateTags(id: string, tags: string[]) {
  try {
    await contentApiClient.patch(`/admin/content/${id}/tags`, {
      tags,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export async function updateCategoryTags(id: string, tags: string[]) {
  try {
    await contentApiClient.patch(`/admin/content/${id}/category-tags`, {
      tags,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export async function updateInternalTags(id: string, tags: string[]) {
  try {
    await contentApiClient.patch(`/admin/content/${id}/internal-tags`, {
      tags,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export async function updateLabelTags(id: string, tags: string[]) {
  try {
    await contentApiClient.patch(`/admin/content/${id}/label-tags`, {
      tags,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export type LabellerAssign = {
  labellerId: string;
  labellerEmail: string;
  labellerNickname: string;
  workDoneTotal: number;
  assignTotal: number;
};

export async function fetchLabellerAssign() {
  try {
    const { data } = await contentApiClient.get<LabellerAssign[]>(
      `/temp/labeller-assign`
    );
    return data;
  } catch (e) {
    return [];
  }
}

export type ChangeLog = {
  change: {
    ADD: {
      LABEL?: string[];
      INTERNAL?: string[];
      CATEGORY?: string[];
    };
    DEL: {
      LABEL?: string[];
      INTERNAL?: string[];
      CATEGORY?: string[];
    };
  };
  changerId: string;
  changerName: string;
  logAt: number;
};
export async function fetchChangeLogs(id: string) {
  try {
    const { data } = await contentApiClient.get<ChangeLog[]>(
      `/admin/content/${id}/change-logs`
    );

    let idx = -1;
    let prevId = "";
    const result: ChangeLog[] = [];
    data.forEach((v) => {
      if (
        prevId !== v.changerId ||
        !compareRound(result[idx].logAt, v.logAt, 500)
      ) {
        idx++;
        prevId = v.changerId;
        result.push(v);
        return;
      }

      if (v.change.ADD.LABEL !== undefined) {
        result[idx].change.ADD.LABEL = [
          ...(result[idx].change.ADD.LABEL ?? []),
          ...v.change.ADD.LABEL,
        ];
      }
      if (v.change.ADD.CATEGORY !== undefined) {
        result[idx].change.ADD.CATEGORY = [
          ...(result[idx].change.ADD.CATEGORY ?? []),
          ...v.change.ADD.CATEGORY,
        ];
      }
      if (v.change.ADD.INTERNAL !== undefined) {
        result[idx].change.ADD.INTERNAL = [
          ...(result[idx].change.ADD.INTERNAL ?? []),
          ...v.change.ADD.INTERNAL,
        ];
      }
      if (v.change.DEL.LABEL !== undefined) {
        result[idx].change.DEL.LABEL = [
          ...(result[idx].change.DEL.LABEL ?? []),
          ...v.change.DEL.LABEL,
        ];
      }
      if (v.change.DEL.CATEGORY !== undefined) {
        result[idx].change.DEL.CATEGORY = [
          ...(result[idx].change.DEL.CATEGORY ?? []),
          ...v.change.DEL.CATEGORY,
        ];
      }
      if (v.change.DEL.INTERNAL !== undefined) {
        result[idx].change.DEL.INTERNAL = [
          ...(result[idx].change.DEL.INTERNAL ?? []),
          ...v.change.DEL.INTERNAL,
        ];
      }
    });

    return result;
  } catch (e) {
    return undefined;
  }
}

function compareRound(a: number, b: number, diff: number) {
  const minDiff = b - diff;
  const maxDiff = b + diff;
  return a >= minDiff && a <= maxDiff;
}

export async function labelDone(id: string) {
  try {
    await contentApiClient.patch(`/temp/content/${id}/label-done`);
    return true;
  } catch (e) {
    return false;
  }
}

export async function labellerAssign(
  body: { contentId: string; labellerId: string }[]
) {
  try {
    await contentApiClient.post(`/temp/content/labeller-assign`, body);
    return true;
  } catch (e) {
    return false;
  }
}
