import { useCallback, useRef } from "react";

export function useTimeout(callback: () => void, ms: number) {
  const timeoutRef = useRef<number>();

  const start = useCallback(() => {
    if (timeoutRef.current !== undefined || window === undefined) {
      return;
    }

    timeoutRef.current = window.setTimeout(callback, ms);
  }, [callback, ms]);

  const clear = useCallback(() => {
    const timeout = timeoutRef.current;
    if (timeout === undefined || window === undefined) {
      return;
    }

    timeoutRef.current = undefined;
    window.clearTimeout(timeout);
  }, []);

  const restart = useCallback(() => {
    clear();
    start();
  }, [start, clear]);

  return {
    start,
    restart,
    clear,
  };
}
