import React, { useCallback } from "react";
import {
  Box,
  Image,
  Flex,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import { menuType, navData, navType } from "../../libs/datas/navData";
import { hasRole } from "../../libs/util/jwt";
import { getAccessToken } from "../../libs/apis/apiClient";
import Empty from "../atom/Empty";
import { Role } from "../../libs/datas/roles";

export default function Nav() {
  const renderItem = useCallback((el: navType) => {
    return (
      isAllow(el.roles) && (
        <ListItem key={el.title}>
          <UnorderedList listStyleType="none" margin="0">
            <ListItem bg={"gray.50"} padding="8px 24px 8px 32px">
              <Text fontSize="xs" fontWeight="semibold">
                {el.title}
              </Text>
            </ListItem>
            <UnorderedList
              listStyleType="none"
              margin="0"
              _hover={{ cursor: "pointer" }}
            >
              {el.menus.map((el) => (
                <Menu key={el.title} {...el} />
              ))}
            </UnorderedList>
          </UnorderedList>
        </ListItem>
      )
    );
  }, []);

  return (
    <Box
      boxShadow={"lg"}
      width="18%"
      height="100%"
      borderRadius="24px"
      bg="white"
      as="nav"
    >
      <Flex justifyContent="center" alignItems="center" padding="30px">
        <Box>
          <Image src={Logo} marginBottom="7px" />
          <Text textAlign="center" color="gray" fontWeight="700">
            admin page
          </Text>
        </Box>
      </Flex>
      <UnorderedList
        listStyleType="none"
        margin="0 0 30px 0"
        color={"gray.500"}
      >
        {navData.map(renderItem)}
      </UnorderedList>
    </Box>
  );
}

type MenuProps = menuType;

function Menu({ to, title, roles }: MenuProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  if (roles === undefined || hasRole(getAccessToken() ?? "", ...roles)) {
    return (
      <Link to={to}>
        <ListItem
          padding="8px 24px 8px 32px"
          fontWeight={match ? "bold" : "semibold"}
          color={match ? "gray.600" : "gray.500"}
        >
          <Text fontSize="sm">{title}</Text>
        </ListItem>
      </Link>
    );
  }

  return (
    <>
      {isAllow(roles) && (
        <Link to={to}>
          <ListItem
            padding="8px 24px 8px 32px"
            fontWeight={match ? "bold" : "semibold"}
            color={match ? "gray.600" : "gray.500"}
          >
            <Text fontSize="sm">{title}</Text>
          </ListItem>
        </Link>
      )}
    </>
  );
}

function isAllow(roles?: Role[]) {
  return roles === undefined || hasRole(getAccessToken() ?? "", ...roles);
}
