import React, { ReactNode } from "react";
import { ChakraProvider, Heading } from "@chakra-ui/react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import theme from "./styles/theme";
import ContentPageLayout from "./components/template/ContentPageLayout";
import EntryPage from "./pages/EntryPage";
import Login from "./pages/login/Login";
import SupplierList from "./pages/supplier";
import SupplierDetail from "./pages/supplier/Detail";
import SupplierDetailEdit from "./pages/supplier/components/UserDetailEdit";
import AddSupplier from "./pages/supplier/AddSupplier";
import MonitoringList from "./pages/monitoring";
import MonitoringDetail from "./pages/monitoring/Detail";
import InspectionLog from "./pages/monitoring/components/InspectionLog";
import Reject from "./pages/monitoring/components/Reject";
import NotFoundPage from "./pages/NotFoundPage";
import UnderConstructionPage from "./pages/UnderConstructionPage";
import LicenseGeneratorPage from "./pages/util/LicenseGenerator";
import AuthProvider from "./layout/AuthProvider";
import Unauthorized from "./pages/unauthorized";
import ContentListPage from "./pages/content";
import DetailPage from "./pages/content/Detail";
import { AllowRoles, Role } from "./libs/datas/roles";
import LabelWorkDashboard from "./pages/label";

const queryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {/* entry */}
            <Route index element={<EntryPage />} />

            {/* login page */}
            <Route path="login" element={<Login />} />

            {/* content pages */}
            <Route
              path="*"
              element={<AuthContentPageLayout roles={AllowRoles} />}
            >
              <Route path="home" element={<Heading>관리자 페이지</Heading>} />
              {/* supplier pages */}
              <Route
                path="supplier"
                element={<AuthOutlet roles={["SUPER_ADMIN"]} />}
              >
                <Route index element={<SupplierList />} />
                <Route path=":id" element={<SupplierDetail />} />
                <Route path=":id/edit" element={<SupplierDetailEdit />} />
                <Route path="adduser" element={<AddSupplier />} />
              </Route>

              <Route path="monitoring">
                <Route index element={<MonitoringList />} />
                <Route path=":id" element={<MonitoringDetail />}>
                  <Route index element={<InspectionLog />} />
                  <Route path="edit" element={<Reject />} />
                </Route>
              </Route>

              <Route path="content">
                <Route index element={<ContentListPage />} />
                <Route path=":id" element={<DetailPage />} />
              </Route>

              <Route
                path="label-work-dashboard"
                element={<LabelWorkDashboard />}
              />

              {/* others */}
              {/* ... */}

              {/* under construction */}
              <Route
                path="under-construction"
                element={<UnderConstructionPage />}
              />

              <Route path="util">
                <Route
                  path="license-generator"
                  element={<LicenseGeneratorPage />}
                />
              </Route>

              {/* to not found */}
              <Route path="*" element={<Navigate to="not-found" />} />
            </Route>

            <Route path="unauthorized" element={<Unauthorized />} />

            {/* not found */}
            <Route path="not-found" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;

type AuthContentPageLayoutProps = {
  roles: Role[];
};

function AuthContentPageLayout({ roles }: AuthContentPageLayoutProps) {
  return (
    <Auth roles={roles}>
      <ContentPageLayout />
    </Auth>
  );
}

type AuthOutletProps = {
  roles: Role[];
};

function AuthOutlet({ roles }: AuthOutletProps) {
  return (
    <Auth roles={roles}>
      <Outlet />
    </Auth>
  );
}

type AuthProps = {
  roles: Role[];
  children: ReactNode;
};

function Auth({ roles, children }: AuthProps) {
  return (
    <AuthProvider
      loginPath="/login"
      unauthorizedPath="/unauthorized"
      roles={roles}
    >
      {children}
    </AuthProvider>
  );
}
